import React from "react";

import PublicHome from "./Public/PublicHome";

import { Button } from "neetoui";

const Hero = () => (
  <PublicHome/>

  // <div className="flex h-screen flex-row items-center justify-center">
  //   <div className="m-auto flex max-w-3xl flex-col items-center justify-center p-8">

  //     <h1 className="mb-3 text-4xl font-bold">Wheel</h1>
  //     <div className="mt-6 flex items-center justify-center">
  //       <Button label="Login" to="/login" type="primary" />
  //     </div>
  //   </div>
  // </div>
);

export default Hero;
