import React from "react";

import { ArrowDownTrayIcon } from '@heroicons/react/20/solid'

const DownloadJSON = ({
  data,
  fileName
}) => {
  const exportData = () => {
    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
      JSON.stringify(data)
    )}`
    const link = document.createElement("a")
    link.href = jsonString
    link.download = `${fileName}.json`
    link.click()
  }

  return (
      <button type="button" onClick={exportData} className="inline-flex items-center gap-x-2 rounded-md bg-primary-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600" >
        Свали файл
        <ArrowDownTrayIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
      </button>
  );
};

export default DownloadJSON;
