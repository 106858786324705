import React from "react";

import { Route, Redirect, Switch, NavLink, useLocation } from "react-router-dom";

import {
  DASHBOARD_ROUTES,
  HOME_PATH,
  DASHBOARD_PATH,
  MY_TESTS_PATH,
  SUBJECTS_PATH,
  SUBSCRIPTION_PATH,
  ADMIN_PATH,
  CHANGE_PASSWORD_PATH,
  CHANGE_EMAIL_PATH,
  PROFILE_PATH
} from "components/routeConstants";

import LogoSpin from "components/commons/LogoSpin";

/*
  This example requires some changes to your tailwind.config.js:
  module.exports = {
    plugins: [
      require('@tailwindcss/forms'),
    ]
  }
*/
import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Bars3Icon,
  BellIcon,
  QueueListIcon,
  ArrowLeftOnRectangleIcon,
  CreditCardIcon,
  PaperClipIcon,
  HomeIcon,
  CursorArrowRaysIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

import authenticationApi from "apis/authentication";
import { LOGIN_PATH } from "components/routeConstants";
import { useAuthDispatch } from "contexts/auth";

import { useUserState } from "contexts/user";

import { Search } from "./Search";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NiceSidebar() {
  const [sidebarOpen, setSidebarOpen] = useState(false)

  const { pathname } = useLocation();
  const { user } = useUserState();
  const authDispatch = useAuthDispatch();
  const handleLogout = async () => {
    try {
      await authenticationApi.logout();
      authDispatch({ type: "LOGOUT" });
      window.location.href = LOGIN_PATH;
    } catch (error) {
      logger.error(error);
    }
  };

  // user.role != 'super_admin'
  const navigation = [
    { name: 'Начало', href: HOME_PATH, icon: HomeIcon, current: (pathname === HOME_PATH) },
    { name: 'Моите тестове', href: MY_TESTS_PATH, icon: QueueListIcon, current: (pathname === MY_TESTS_PATH) },
    { name: 'Всички тестове', href: SUBJECTS_PATH, icon: CursorArrowRaysIcon, current: (pathname === SUBJECTS_PATH) },
    { name: 'Абонамент', href: SUBSCRIPTION_PATH, icon: CreditCardIcon, current: (pathname === SUBSCRIPTION_PATH) },
    ... user.role === 'super_admin' ? [{ name: 'Създай нов тест', href: ADMIN_PATH, icon: PaperClipIcon, current: (pathname === ADMIN_PATH) }] : []
  ]
  const teams = [
    { id: 1, name: 'Профил', href: PROFILE_PATH, initial: 'П', current: (pathname === PROFILE_PATH) },
    { id: 2, name: 'Имейл', href: CHANGE_EMAIL_PATH, initial: 'И', current: (pathname === CHANGE_EMAIL_PATH) },
    { id: 3, name: 'Парола', href: CHANGE_PASSWORD_PATH, initial: 'П', current: (pathname === CHANGE_PASSWORD_PATH) },
  ]
  const userNavigation = [
    { name: 'Моят профил', href: PROFILE_PATH },
    { name: 'Имейл', href: CHANGE_EMAIL_PATH },
    { name: 'Парола', href: CHANGE_PASSWORD_PATH },
    { name: 'Излез', href: LOGIN_PATH, onClick: handleLogout },
  ]

  return (
    <>
      {/*
        This example requires updating your template:
        <html class="h-full bg-white">
        <body class="h-full">
      */}
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-neutral-900 bg-opacity-80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-neutral-50 px-6 pb-4">
                    <NavLink to={HOME_PATH} className="flex h-16 shrink-0 items-center" onClick={() => setSidebarOpen(false)}>
                      <LogoSpin height="tiny" classes="pt-4 m-auto" />
                      {/*<img
                        className="h-10 mt-4 w-auto"
                        src="/bg-indigo4.svg"
                        alt="Eleanor"
                      />*/}
                      {/*<div className="p-2">Eleanor</div >*/}
                    </NavLink>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <NavLink
                                  to={item.href}
                                  className={classNames(
                                    item.current
                                      ? 'bg-neutral-100 text-primary-600'
                                      : 'text-neutral-700 hover:text-primary-600 hover:bg-neutral-100',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium'
                                  )}
                                  onClick={() => setSidebarOpen(false)}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current ? 'text-primary-600' : 'text-neutral-400 group-hover:text-primary-600',
                                      'h-6 w-6 shrink-0'
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <div className="text-xs font-medium leading-6 text-neutral-400">Настройки</div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {teams.map((team) => (
                              <li key={team.name}>
                                <NavLink
                                  to={team.href}
                                  className={classNames(
                                    team.current
                                      ? 'bg-neutral-100 text-primary-600'
                                      : 'text-neutral-700 hover:text-primary-600 hover:bg-neutral-100',
                                    'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium'
                                  )}
                                  onClick={() => setSidebarOpen(false)}
                                >
                                  <span
                                    className={classNames(
                                      team.current
                                        ? 'text-primary-600 border-primary-600'
                                        : 'text-neutral-400 border-neutral-200 group-hover:border-primary-600 group-hover:text-primary-600',
                                      'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                                    )}
                                  >
                                    {team.initial}
                                  </span>
                                  <span className="truncate">{team.name}</span>
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="mt-auto">
                          <NavLink
                            to={LOGIN_PATH}
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-medium leading-6 text-neutral-700 hover:bg-neutral-100 hover:text-primary-600"
                            onClick={handleLogout}
                          >
                            <ArrowLeftOnRectangleIcon
                              className="h-6 w-6 shrink-0 text-neutral-400 group-hover:text-primary-600"
                              aria-hidden="true"
                            />
                            Излезте от акаунта
                          </NavLink>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-neutral-200 bg-neutral-50 px-6 pb-4">
            <NavLink to={HOME_PATH} className="flex h-16 shrink-0 items-center">
              <LogoSpin height="tiny" classes="pt-4 m-auto" />
              {/*<img
                className="h-10 mt-4 w-auto"
                src="/bg-indigo4.svg"
                alt="Eleanor"
              />*/}
              {/*<div className="p-2">Eleanor</div >*/}
            </NavLink>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <NavLink
                          to={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-neutral-100 text-primary-600'
                              : 'text-neutral-700 hover:text-primary-600 hover:bg-neutral-100',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium'
                          )}
                          onClick={() => setSidebarOpen(false)}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-primary-600' : 'text-neutral-400 group-hover:text-primary-600',
                              'h-6 w-6 shrink-0'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  <div className="text-xs font-medium leading-6 text-neutral-400">Настройки</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <NavLink
                          to={team.href}
                          className={classNames(
                            team.current
                              ? 'bg-neutral-100 text-primary-600'
                              : 'text-neutral-700 hover:text-primary-600 hover:bg-neutral-100',
                            'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-medium'
                          )}
                        >
                          <span
                            className={classNames(
                              team.current
                                ? 'text-primary-600 border-primary-600'
                                : 'text-neutral-400 border-neutral-200 group-hover:border-primary-600 group-hover:text-primary-600',
                              'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white'
                            )}
                          >
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <NavLink
                    to={LOGIN_PATH}
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-medium leading-6 text-neutral-700 hover:bg-neutral-100 hover:text-primary-600"
                    onClick={handleLogout}
                  >
                    <ArrowLeftOnRectangleIcon
                      className="h-6 w-6 shrink-0 text-neutral-400 group-hover:text-primary-600"
                      aria-hidden="true"
                    />
                    Излезте от акаунта
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-72">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-neutral-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" className="-m-2.5 p-2.5 text-neutral-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div className="h-6 w-px bg-neutral-200 lg:hidden" aria-hidden="true" />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <div className="relative flex flex-1">
                <Search />
{/*                <MagnifyingGlassIcon
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-neutral-400"
                  aria-hidden="true"
                />
                <input
                  id="search-field"
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-neutral-900 placeholder:text-neutral-400 focus:ring-0 sm:text-sm"
                  placeholder="Търсене..."
                  type="search"
                  name="search"
                />*/}
              </div>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
                <button type="button" className="-m-2.5 p-2.5 text-neutral-400 hover:text-neutral-500">
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Separator */}
                <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-neutral-200" aria-hidden="true" />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-neutral-400">
                      <span className="text-sm font-medium leading-none text-white">
                      {user.first_name[0]}{user.last_name[0]}
                      </span>
                    </span>
                    <span className="hidden lg:flex lg:items-center">
                      <span className="ml-4 text-sm font-medium leading-6 text-neutral-900" aria-hidden="true">
                        {user.first_name} {user.last_name}
                      </span>
                      <ChevronDownIcon className="ml-2 h-5 w-5 text-neutral-400" aria-hidden="true" />
                    </span>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-neutral-900/5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <NavLink
                              to={item.href}
                              className={classNames(
                                active ? 'bg-neutral-100' : '',
                                'block px-3 py-1 text-sm leading-6 text-neutral-900'
                              )}
                              onClick={item.onClick}
                            >
                              {item.name}
                            </NavLink>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="py-10">
            <div className="px-4 sm:px-6 lg:px-8">
              <Switch>
                {DASHBOARD_ROUTES.map(({ path, component }) => (
                  <Route exact component={component} key={path} path={path} />
                ))}
                <Redirect from={DASHBOARD_PATH} to={HOME_PATH} />
              </Switch>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}
