import React, { Fragment, useState } from "react"
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function MyListBox({
  values,
  initialSelected,
  onSelection
}) {
  const [selected, setSelected] = useState(values.find((v) => v.name == initialSelected) || values[0])

  function onValueSelection(e) {
    setSelected(e);
    onSelection(e.name);
  }

  function renderCheckIcon() {
    if (selected.checkIcon) {
      return selected.checkIcon;
    }

    return (
      <CheckIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
    )
  }

  return (
    <Listbox value={selected} onChange={onValueSelection}>
      {({ open }) => (
        <>
          <div className="relative">
            <div className="inline-flex divide-x divide-primary-700 rounded-md shadow-sm">
              <div className="inline-flex items-center gap-x-1.5 rounded-l-md bg-primary-600 px-3 py-2 text-white shadow-sm">
                {renderCheckIcon()}
                <p className="text-sm font-semibold">{selected.name}</p>
              </div>
              <Listbox.Button className="inline-flex items-center rounded-l-none rounded-r-md bg-primary-600 p-2 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-600 focus:ring-offset-2 focus:ring-offset-neutral-50">
                <span className="sr-only">Change published status</span>
                <ChevronDownIcon className="h-5 w-5 text-white" aria-hidden="true" />
              </Listbox.Button>
            </div>

            <Listbox.Options
              transition="true"
              className="absolute right-0 z-20 mt-2 w-72 origin-top-right divide-y divide-neutral-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in"
            >
              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                {values.map((option) => (
                  <Listbox.Option
                    key={option.name}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-primary-600 text-white' : '',
                        !active ? 'text-neutral-900' : '',
                        'cursor-default select-none p-4 text-sm',
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p className={selected ? 'font-semibold' : 'font-normal'}>{option.name}</p>
                          {selected ? (
                            <span className={active ? 'text-white' : 'text-primary-600'}>
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </div>
                        <p className={classNames(active ? 'text-primary-200' : 'text-neutral-500', 'mt-2')}>
                          {option.description}
                        </p>
                      </div>
                    )}
                  </Listbox.Option>
                ))}
              </Transition>
            </Listbox.Options>
          </div>
        </>
      )}
    </Listbox>
  )
}
