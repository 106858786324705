import React, { useState, useEffect } from "react";
import ReactHtmlParser from 'react-html-parser';
import { uuid } from "utils/uuid";
import { displayableGoogleDriveImageLink } from "utils/googleDrive";
import { DebounceInput } from 'react-debounce-input';

import MyMath from "components/commons/MyMath";
import MyRealMath from "components/commons/MyRealMath";
import Flyout from "components/commons/Flyout";
import Toggle from "components/commons/Toggle";
import RadioChoice from "components/commons/RadioChoice";
import Divider from "components/commons/Divider";
import Question from "../Test/Question";
import Mathable from "components/commons/Mathable";
import MyComboBox from "components/commons/MyComboBox";

import { CheckIcon } from '@heroicons/react/20/solid'

function input(value, entityName, label, rows, onChange) {
  return (
    <div>
      <label htmlFor={entityName} className="block text-sm font-medium leading-6 text-neutral-900">
        {ReactHtmlParser(label)}
      </label>
      <div className="mt-2">
        <DebounceInput
          element="textarea"
          debounceTimeout={500}
          value={value}
          onChange={onChange}
          name={entityName}
          id={entityName}
          rows={rows}
          className="block w-full rounded-md border-0 py-1.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
        />
      </div>
    </div>
  )
}

function emptyAnswer(correct = false) {
  return {id: uuid(), value: '', correct: correct, explanation: ''}
}

function emptySubquestion() {
  return {id: uuid(), title: '', text_answer: '', long_text_answer: '', explanation: '', points: 1}
}

function noOp() {}

function xIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="w-5 h-5 m-auto cursor-pointer">
      <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z" />
    </svg>
  )
}

function downArrowIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 m-auto text-neutral-600">
      <path strokeLinecap="round" strokeLinejoin="round" d="m9 12.75 3 3m0 0 3-3m-3 3v-7.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
    </svg>
  )
}

function imagePrompt() {
  return 'Поставете линк към изображение от папката <u><a class="text-sky-700" href="https://drive.google.com/drive/folders/1kqdl547yCu58Ci2w7KE7QmWmCuOWeNNe?usp=drive_link" target="_blank">Еленор</a></u>.';
}

const QuestionGenerator = ({
  question,
  index,
  saveQuestion,
  deleteQuestion,
  idLookup,
  availableToDouble
}) => {
  function questionTitle() {
    return input(title, 'title', `Въпрос #${indexNumber()}`, 3, e => setTitle(e.target.value))
  }

  function questionImage() {
    return input(image, 'image', `🖌️ Изображение към въпроса. ${imagePrompt()}`, 1, e => setImage(displayableGoogleDriveImageLink(e.target.value)))
  }

  function questionHint() {
    return input(hint, 'hint', `Жокер към въпрос #${indexNumber()}`, 7, e => setHint(e.target.value))
  }

  function questionHintImage() {
    return input(hintImage, 'hintImage', `🖌️ Изображение към жокера. ${imagePrompt()}`, 1, e => setHintImage(displayableGoogleDriveImageLink(e.target.value)))
  }

  function questionTextAbove() {
    return (
      <>
        <div className="mt-4">
          {input(textAbove, 'textAbove', 'Текст над въпроса', 10, e => setTextAbove(e.target.value))}
        </div>
      </>
    )
  }

  function questionNoteBelow() {
    return (
      <>
        <div className="mt-4">
          {input(noteBelow, 'noteBelow', 'Бележка под въпроса', 2, e => setNoteBelow(e.target.value))}
        </div>
      </>
    )
  }

  function questionLongTextAnswer() {
    return (
      <>
        {input(longTextAnswer, 'longTextAnswer', 'Примерен верен отговор (използвайте тагове или формули)', 5, e => setLongTextAnswer(e.target.value))}

        <div className="block text-sm font-medium leading-6 text-neutral-900 italic">Превю на примерния дълъг отговор:</div>
        <div className="whitespace-pre-line">
          <MyRealMath text={longTextAnswer}/>
        </div>
      </>
    )
  }

  function hintPreview() {
    const visible = hint.length > 0 || hintImage.length > 0

    return (
      <>
        {visible && (
          <>
            <div className="block text-sm font-medium leading-6 text-neutral-900 italic">Превю на жокера:</div>
            <div className="whitespace-pre-line">
              <MyRealMath text={hint}/>
              {hintImage.length > 0 && (
                <img src={hintImage} className="m-auto p-5" />
              )}
            </div>
          </>
        )}
      </>
    )
  }

  function addAnswer() {
    setAnswers([...answers, emptyAnswer()])
  }

  function addSubquestion() {
    setSubquestions([...subquestions, emptySubquestion()])
  }

  function removeAnswer(answer) {
    if (answers.length > 1) {
      setAnswers(answers.filter(a => a.id !== answer.id));
    }
  }

  function removeSubquestion(subquestion) {
    if (subquestions.length > 2) {
      setSubquestions(subquestions.filter(s => s.id !== subquestion.id));
    }
  }

  function updateAnswer(answer, newValue, property = 'value') {
    const nextAnswers = answers.map((a) => {
      if (a.id === answer.id) {
        return {...answer, [property]: newValue};
      } else {
        return a;
      }
    });

    setAnswers(nextAnswers);
  }

  function updateSubquestion(newValue, subquestion, field) {
    const nextSubquestions = subquestions.map((s) => {
      if (s.id === subquestion.id) {
        return {...subquestion, [field]: newValue};
      } else {
        return s;
      }
    });

    setSubquestions(nextSubquestions);
  }

  function indexNumber() {
    if (typeof index === 'string') {
      return index;
    }
    return isDouble ? `${(doublesAboveQuestion ? (index - 1) : doublesQuestionIndex)}↺` : index
  }

  function questionPoints() {
    if (kind === 'open_with_subquestions' || kind === 'open_with_long_answer_subquestions' || isDouble) {
      return;
    }

    return (
      <div className="flex gap-2">
        <label htmlFor="points-number" className="block my-auto text-sm font-medium leading-6 text-neutral-900">
          Носи брой точки
        </label>
        <div>
          <input value={points} onChange={e => setPoints(e.target.value)} type="number" name="number" min="1" max="100" id="points-number" className="block my-auto w-20 rounded-md border-0 py-1.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
        </div>
      </div>
    )
  }

  function subquestionPoints(subquestion) {
    return (
      <div className="mt-3 flex gap-3">
        <label htmlFor="points-number" className="block my-auto text-sm font-medium leading-6 text-neutral-900">
          Подточката носи брой точки
        </label>
        <div>
          <input value={subquestion.points} onChange={e => updateSubquestion(e.target.value, subquestion, 'points')} type="number" name="number" min="1" max="100" id="points-number" className="block my-auto w-20 rounded-md border-0 py-1.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
        </div>
      </div>
    )
  }


  function serialize() {
    const serializedHint = hint.length === 0 ? undefined : {text: hint, image_url: (hintImage.length > 0 ? hintImage : null)}
    const doubledIndex = doublesAboveQuestion ? index - 1 : doublesQuestionIndex;
    const totalPoints = (kind === 'open_with_subquestions' || kind === 'open_with_long_answer_subquestions') ? subquestions.map((s) => parseInt(s.points) || 0).reduce((partialSum, a) => partialSum + a, 0) : points

    return {
      id: question.id,
      index: indexNumber(),
      title: title,
      image_url: (image.length > 0 ? image : null),
      hint: serializedHint,
      text_above_question: (hasTextAbove && textAbove.length > 0 ? textAbove : null),
      note_below_question: (hasNoteBelow && noteBelow.length > 0 ? noteBelow : null),
      long_text_answer: (longTextAnswer.length > 0 ? longTextAnswer : null),
      explanation: correctAnswer?.explanation,
      is_double: isDouble,
      doubles_above_question: doublesAboveQuestion,
      doubles_question_index: doublesAboveQuestion ? index - 1 : doublesQuestionIndex,
      doubles_question_id: idLookup(doubledIndex),
      points: totalPoints,
      display_points: totalPoints ? totalPoints : 1,
      kind: kind,
      answers: [correctAnswer, ...answers], // if it's not classic this will be present but the parser can ignore it
      subquestions: subquestions // they lack index but it can be deducted by the order
    }
  }

  function save() {
    saveQuestion(serialize())
    setExpanded(false)
  }

  function initialCorrectAnswer() {
    return question.answers?.find((a) => a.correct === true) || emptyAnswer(true)
  }

  function initialWrongAnswers() {
    return question.answers?.filter((a) => a.correct === false) || [emptyAnswer(), emptyAnswer(), emptyAnswer()]
  }

  function initialSubquestions() {
    return question.subquestions || [emptySubquestion(), emptySubquestion()]
  }

  function initialExpanded() {
    return question.title?.length > 0 ? false : true
  }

  function initialHasExplanations() {
    return question.answers?.filter((a) => a.explanation?.length > 0)?.length > 0
  }

  function onDoubleIndexSelected(e) {
    setDoublesQuestionIndex(e);
    indexNumber();
  }

  function renderDoubleForm() {
    return (
      <fieldset>
        <legend className="sr-only">Notifications</legend>
        <div className="space-y-5">
          <div className="relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id="comments"
                name="comments"
                type="checkbox"
                checked={doublesAboveQuestion}
                onChange={e => setDoublesAboveQuestion(!doublesAboveQuestion)}
                className="h-4 w-4 rounded border-neutral-300 text-primary-600 focus:ring-primary-600"
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label htmlFor="comments" className="font-medium text-neutral-900">
                Дублира горния въпрос
              </label>{' '}
              <span className="text-neutral-500">
                или изберете индекса на дублирания въпрос.
              </span>
            </div>
          </div>
          {!doublesAboveQuestion && (
            <div className="flex gap-3">
              <label htmlFor="index-number" className="block my-auto text-sm font-medium leading-6 text-neutral-900">
                Индекс на дублирания въпрос
              </label>
              <div className="w-24">
                <MyComboBox
                  initialSelected={doublesQuestionIndex}
                  values={availableToDouble(question)}
                  onSelection={onDoubleIndexSelected}
                />
              </div>
            </div>
          )}
        </div>
      </fieldset>
    )
  }

  function renderIsDouble() {
    return (
      <div className="flex flex-col space-y-4 bg-neutral-100 px-4 py-3">
        <Toggle
          enabled={isDouble}
          setEnabled={setIsDouble}
          title="Въпросът е дубльор ↺"
          subtitle="Включете ако искате този въпрос да е спомагателен на друг."
          titleClasses="text-sm font-medium text-neutral-900"
        />
        {isDouble && renderDoubleForm()}
      </div>
    )
  }

  function renderTextAboveSection() {
    return (
      <div className="bg-neutral-100 px-4 py-3">
        <Toggle
          enabled={hasTextAbove}
          setEnabled={setHasTextAbove}
          title="Текст над въпроса"
          subtitle="Включете ако искате да добавите текст отгоре над въпроса."
          titleClasses="text-sm font-medium text-neutral-900"
        />
        {hasTextAbove && questionTextAbove()}
      </div>
    )
  }

  function renderNoteBelowSection() {
    return (
      <div className="bg-neutral-100 px-4 py-3">
        <Toggle
          enabled={hasNoteBelow}
          setEnabled={setHasNoteBelow}
          title="Бележка под въпроса"
          subtitle="Ако искате да добавите указания, напр. формат на отворен отговор."
          titleClasses="text-sm font-medium text-neutral-900"
        />
        {hasNoteBelow && questionNoteBelow()}
      </div>
    )
  }

  function renderHasExplanationsToggle() {
    return (
      <div className="bg-neutral-100 px-4 py-3">
        <Toggle
          enabled={hasExplanations}
          setEnabled={setHasExplanations}
          title="Обяснения на отговорите 💭"
          subtitle="Ако искате след като ученик даде отговор да се показват обяснения."
          titleClasses="text-sm font-medium text-neutral-900"
        />
      </div>
    )
  }

  const [title, setTitle] = useState(question.title || '')
  const [image, setImage] = useState(question.image_url || '')
  const [hint, setHint] = useState(question.hint?.text || '')
  const [hintImage, setHintImage] = useState(question.hint?.image_url || '')
  const [correctAnswer, setCorrectAnswer] = useState(initialCorrectAnswer())
  const [answers, setAnswers] = useState(initialWrongAnswers())
  const [kind, setKind] = useState(question.kind || 'classic')
  const [hasExplanations, setHasExplanations] = useState(initialHasExplanations())
  const [hasTextAbove, setHasTextAbove] = useState(question.text_above_question?.length > 0 || false)
  const [hasNoteBelow, setHasNoteBelow] = useState(question.note_below_question?.length > 0 || false)
  const [textAbove, setTextAbove] = useState(question.text_above_question || '')
  const [noteBelow, setNoteBelow] = useState(question.note_below_question || '')
  const [longTextAnswer, setLongTextAnswer] = useState(question.long_text_answer || '')
  const [subquestions, setSubquestions] = useState(initialSubquestions())
  const [isDouble, setIsDouble] = useState(question.is_double || false)
  const [doublesAboveQuestion, setDoublesAboveQuestion] = useState(question.doubles_above_question != null ? question.doubles_above_question : false)
  const [doublesQuestionIndex, setDoublesQuestionIndex] = useState(question.doubles_question_index)
  const [points, setPoints] = useState(question.points || 1)
  const [expanded, setExpanded] = useState(initialExpanded())

  const [delayedPreview, setDelayedPreview] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setDelayedPreview(true);
    }, 50);
  }, []);

  useEffect(() => {
    if (isDouble && (kind === 'open_with_subquestions' || kind === 'open_with_long_answer_subquestions')) {
      setKind('classic');
    }
  }, [isDouble]);

  function allKinds() {
    return [
      {name: 'classic',                             label: 'Класически',                 enabled: true, id: uuid()},
      {name: 'open',                                label: 'Отворен',                    enabled: true, id: uuid()},
      {name: 'open_with_subquestions',              label: 'Отворен с подточки',         enabled: !isDouble, id: uuid()},
      {name: 'open_with_long_answer',               label: 'С дълъг отговор',            enabled: true, id: uuid()},
      {name: 'open_with_long_answer_subquestions',  label: 'С подточки с дълъг отговор', enabled: !isDouble, id: uuid()}
    ]
  }

  return (
    <>
      <Flyout
        title={`Въпрос ${indexNumber()}. ${question.title || ''}`}
        open={expanded}
        setOpen={setExpanded}
        object={
          <div className="mx-auto px-6 py-6">
            <div className="flex flex-col space-y-5">
              <RadioChoice value={kind} setValue={setKind} allValues={allKinds()} title={'Изберете вид въпрос'} subtitle={'Изберете вида въпрос спрямо вида и сложността на отговора му.'}/>

              {renderIsDouble()}
              {renderTextAboveSection()}
              {questionTitle()}
              {questionPoints()}
              {!isDouble && (kind === 'classic' || kind === 'open' || kind === 'open_with_subquestions') && renderHasExplanationsToggle()}

              {(kind === 'classic' || kind === 'open') && (
                <div>
                  <div className="py-2">
                    Верен отговор
                    {kind === 'open' && (<span className="italic font-light text-xs"> (без тагове/формули)</span>)}
                  </div>
                  <DebounceInput debounceTimeout={500} onChange={e => setCorrectAnswer({...correctAnswer, value: e.target.value})} value={correctAnswer.value} name={correctAnswer.id} id={correctAnswer.id} type="text" className="block w-full rounded-md border-0 py-1.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6" />

                  {hasExplanations && (
                    <>
                      <div className="py-2"> Обяснение за верния отговор </div>
                      <DebounceInput debounceTimeout={500} element="textarea" rows={2} onChange={e => setCorrectAnswer({...correctAnswer, explanation: e.target.value})} value={correctAnswer.explanation} type="text" className="block w-full rounded-md border-0 py-1.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6" />
                    </>
                  )}
                </div>
              )}

              {kind === 'classic' && (
                <>
                  <div className="flex flex-col space-y-3 pt-2">
                    {!hasExplanations && (<div>Грешни отговори</div>)}
                    {answers.map((answer, i) => (
                      <div key={answer.id}>
                        {hasExplanations && (
                          <>
                            <div className="pt-3 pb-1"><Divider /></div>
                            <div className="py-2">Грешен отговор</div>
                          </>
                        )}
                        <div className="w-full inline-flex">
                          <DebounceInput debounceTimeout={500} onChange={e => updateAnswer(answer, e.target.value)} value={answer.value} name={answer.id} id={answer.id} type="text" className="block w-[95%] rounded-md border-0 py-1.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6" />
                          <span className="w-[5%] m-auto" onClick={() => removeAnswer(answer)}>
                            {xIcon()}
                          </span>
                        </div>
                        {hasExplanations && (
                          <>
                            <div className="py-2"> Обяснение за този грешен отговор </div>
                            <DebounceInput debounceTimeout={500} element="textarea" rows={2} onChange={e => updateAnswer(answer, e.target.value, 'explanation')} value={answer.explanation} type="text" className="block w-[95%] rounded-md border-0 py-1.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6" />
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                  <div><button onClick={addAnswer}>+ Добави грешен отговор</button></div>
                </>
              )}

              {questionImage()}

              {kind === 'open_with_subquestions' && (
                <>
                  <div className="text-base font-semibold text-neutral-700">Подточки</div>
                  {subquestions.map((subquestion, i) => (
                    <div key={subquestion.id}>
                      <div className="w-full inline-flex pt-1">
                        <div className="w-[95%] block">
                          {input(subquestion.title, subquestion.id, 'Подточка - започнете с А), Б), ...', 2, e => updateSubquestion(e.target.value, subquestion, 'title'))}
                          <div className="py-2">Верен отговор на подточката</div>

                          <DebounceInput debounceTimeout={500} onChange={e => updateSubquestion(e.target.value, subquestion, 'text_answer')} value={subquestion.text_answer} name={subquestion.id} id={subquestion.id} type="text" className="w-full rounded-md border-0 py-1.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6" />
                        </div>
                        <span className="w-[5%] mx-auto" onClick={() => removeSubquestion(subquestion)}>
                          {xIcon()}
                        </span>
                      </div>
                      {subquestionPoints(subquestion)}

                      {hasExplanations && (
                        <>
                          <div className="py-2"> Обяснение за верния отговор </div>
                          <DebounceInput debounceTimeout={500} element="textarea" rows={2} onChange={e => updateSubquestion(e.target.value, subquestion, 'explanation')} value={subquestion.explanation} type="text" className="block w-[95%] rounded-md border-0 py-1.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6" />
                        </>
                      )}
                      {i != subquestions.length - 1 && <div className="pt-10 pb-2"><Divider /></div>}
                    </div>
                  ))}
                  <div><button onClick={addSubquestion}>+ Добави подточка</button></div>
                </>
              )}

              {kind === 'open_with_long_answer' && (
                <> {questionLongTextAnswer()} </>
              )}

              {kind === 'open_with_long_answer_subquestions' && (
                <>
                  <div className="text-base font-semibold text-neutral-700">Подточки</div>
                  {subquestions.map((subquestion, i) => (
                    <div key={subquestion.id}>
                      <div className="w-full inline-flex pt-1">
                        <div className="w-[95%] block">
                          {input(subquestion.title, subquestion.id, 'Подточка - започнете с А), Б), ...', 2, e => updateSubquestion(e.target.value, subquestion, 'title'))}
                          <div className="py-2">Примерен верен отговор на подточката - използвайте тагове/формули</div>
                          <DebounceInput element="textarea" rows={5}  debounceTimeout={500} onChange={e => updateSubquestion(e.target.value, subquestion, 'long_text_answer')} value={subquestion.long_text_answer} name={subquestion.id} id={subquestion.id} type="text" className="w-full rounded-md border-0 py-1.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6" />
                          {subquestionPoints(subquestion)}

                          <div className="py-3">
                            <div className="block text-sm font-medium leading-6 text-neutral-900 italic">Превю на примерния дълъг отговор:</div>
                            <div className="whitespace-pre-line mt-2">
                              <MyRealMath text={subquestion.long_text_answer}/>
                            </div>
                          </div>
                        </div>
                        <span className="w-[5%] mx-auto" onClick={() => removeSubquestion(subquestion)}>
                          {xIcon()}
                        </span>
                      </div>

                      {i != subquestions.length - 1 && <div className="py-5"><Divider /></div>}
                    </div>
                  ))}
                  <div><button onClick={addSubquestion}>+ Добави подточка</button></div>
                </>
              )}

              {renderNoteBelowSection()}
              {downArrowIcon()}

              {delayedPreview && (
                <Question
                  question={serialize()}
                  recordAnswer={noOp}
                  unrecordAnswer={noOp}
                  openHint={noOp}
                  realMath={true}
                  renderingFromAdminPreview={true}
                />
              )}

              {!isDouble && (
                <>
                  {questionHint()}
                  {questionHintImage()}
                  {hintPreview()}
                </>
              )}

              <div className="m-auto pb-4">
                <button onClick={save} type="button" className="inline-flex items-center gap-x-1.5 rounded-md bg-primary-50 px-3 py-2 text-sm font-semibold text-primary-600 shadow-sm hover:bg-primary-100 mt-7">
                  <CheckIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  Запази въпрос
                </button>
              </div>

              <div onClick={() => deleteQuestion(question)} className="cursor-pointer m-auto">
                Изтрий въпрос
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};

export default QuestionGenerator;
