import React, { useState, useEffect } from "react";

import { PageLoader } from "neetoui";

import subjectsApi from "apis/subjects";
import EmptyState from "components/commons/EmptyState";
import EmptyListImage from "images/EmptyNotesList";

import SubjectsList from "./SubjectsList";

const Subjects = () => {
  const [loading, setLoading] = useState(true);
  const [subjects, setSubjects] = useState({});

  useEffect(() => {
    fetchSubjects();
  }, []);

  const fetchSubjects = async () => {
    try {
      setLoading(true);
      const {
        data: { subjects },
      } = await subjectsApi.fetch();
      setSubjects(subjects);
    } catch (error) {
      logger.error(error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="animate-fade py-10">
             <PageLoader text="Един момент..."/>
           </div>;
  }

  return (
    <>
      <SubjectsList
        fetchSubjects={fetchSubjects}
        subjects={subjects}
      />
    </>
  );
};

export default Subjects;
