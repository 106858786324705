import React from "react";

import { Route, Redirect, Switch } from "react-router-dom";
import NiceSidebar from "./NiceSidebar";

const Dashboard = () => (
  <NiceSidebar />
);

export default Dashboard;
