import React, { useState } from "react";
import { NavLink } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid'

const SubjectsList = ({
  subjects,
  fetchSubjects
}) => {

  return (
    <>
      <div className="mx-auto flex items-center justify-center">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          {subjects.map(subject => (
            <NavLink
              to={`/subjects/${subject.id}/tests`}
              className="cursor-pointer max-w-sm bg-white border border-neutral-200 rounded-lg shadow-md hover:bg-neutral-50 dark:bg-neutral-800 dark:border-neutral-700 dark:hover:bg-neutral-700"
              key={subject.id}
            >
                <img className="shine-on-hover rounded-t-lg" src={subject.avatar} alt="" />
                <div className="p-4">
                  <h5 className="mb-2 text-2xl font-bold tracking-tight text-neutral-900 dark:text-white">{subject.title}</h5>
                  <p className="mb-3 h-20 font-normal text-neutral-700 dark:text-neutral-400">{subject.description}</p>
                  <button className="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center text-white bg-primary-600 rounded-lg hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-600 dark:focus:ring-primary-700">
                      Избери
                      <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </div>
            </NavLink>
          ))}
        </div>
      </div>
    </>
  );
};

export default SubjectsList;
