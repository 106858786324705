import React, { useState } from "react";
import MyRealMath from "components/commons/MyRealMath";
import CopyToClipboard from "components/commons/CopyToClipboard";
import { displayableGoogleDriveImageLink } from "utils/googleDrive";
import { renderInput } from "./helpers";
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid'

const Legend = () => {
  function imageHelper() {
    return renderInput('legendImage', '', 'Използва се <i>img</i> таг. Поставете линк към изображение от папката <u><a class="text-sky-700" href="https://drive.google.com/drive/folders/1kqdl547yCu58Ci2w7KE7QmWmCuOWeNNe?usp=drive_link" target="_blank">Еленор</a></u>.', 1, inTextImage, e => onImageURLChange(displayableGoogleDriveImageLink(e.target.value)))
  }

  function onImageURLChange(value) {
    setInTextImage(value)

    const tag = value.length > 0 ? `<img class="m-auto" src="${value}"/>` : ''
    setImageTag(tag)
  }

  const [open, setOpen] = useState(true);
  const [inTextImage, setInTextImage] = useState('');
  const [imageTag, setImageTag] = useState('');

  return (
    <div className="bg-neutral-100 p-5 rounded-md">
      <div className="flex gap-1">
        <div>
          <div className="text-base font-semibold text-neutral-700">Легенда</div>
          <p className="text-sm text-neutral-500 pb-4 pt-1">Всички текстове могат да се форматират по следния начин. Основните не се съчетават с математическите формули.</p>
        </div>
        <span onClick={() => setOpen(!open)} className="p-2 cursor-pointer">
          {open && (<ChevronUpIcon className="h-6 w-6" aria-hidden="true" /> )}
          {!open && (<ChevronDownIcon className="h-6 w-6" aria-hidden="true" /> )}
        </span>
      </div>

      {open && (
        <>
          <div className="flex mt-1 mb-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mt-auto pb-1.5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
            </svg>

            <p className="pl-1 mb-1 text-neutral-950">Основни</p>
          </div>
          <p>– за <b>удебелен</b> текст: &lt;b&gt;текст&lt;/b&gt;</p>
          <p>– за <i>наклонен</i> текст: &lt;i&gt;текст&lt;/i&gt;</p>
          <p>– за <u>подчертан</u> текст: &lt;u&gt;текст&lt;/u&gt;</p>
          <p>– за центриран текст копирайте кода отдолу като замените "[центриран текст]" със заглавието, което искате да центрирате:</p>
          <p>&lt;div style="text-align:center;"&gt;[центриран текст]&lt;/div&gt;</p>
          <p>– за отместване навътре при нов абзац поставете следния код: <b>&amp;emsp;&amp;emsp;</b></p>


          <div className="flex mt-2 mb-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mt-auto pb-1.5">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 15.75V18m-7.5-6.75h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V13.5Zm0 2.25h.008v.008H8.25v-.008Zm0 2.25h.008v.008H8.25V18Zm2.498-6.75h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V13.5Zm0 2.25h.007v.008h-.007v-.008Zm0 2.25h.007v.008h-.007V18Zm2.504-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5Zm0 2.25h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V18Zm2.498-6.75h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V13.5ZM8.25 6h7.5v2.25h-7.5V6ZM12 2.25c-1.892 0-3.758.11-5.593.322C5.307 2.7 4.5 3.65 4.5 4.757V19.5a2.25 2.25 0 0 0 2.25 2.25h10.5a2.25 2.25 0 0 0 2.25-2.25V4.757c0-1.108-.806-2.057-1.907-2.185A48.507 48.507 0 0 0 12 2.25Z" />
            </svg>
            <p className="pl-1 mt-4 mb-1 text-neutral-950">Математически формули</p>
          </div>
          <p>– използвайте <u><a className="text-sky-700" href="https://www.imatheq.com/imatheq/com/imatheq/math-equation-editor-latex-mathml.html" target="_blank">помощния сайт</a></u>, създайте формулата и копирайте кода от полето LaTeX</p>
          <p>– за формула на същия ред, напр. <MyRealMath text="$x^2 - 3x - 4 = 0$"/>, заградете с долари: <span>$</span>формула<span>$</span></p>
          <p>– за формула на нов ред в средата с място, напр. <MyRealMath text="$$x = {-b \pm \sqrt{b^2-4ac} \over 2a}$$"/> заградете с два долара: <span>$$</span>формула<span>$$</span></p>

          <div className="flex mt-5 mb-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 mt-auto pb-1">
              <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
            </svg>
            <p className="pl-1 text-neutral-950"> Изображение по средата на текст </p>
          </div>
          {imageHelper()}
          <p className="mt-4 mb-1">Копирайте следния елемент и го поставете вътре в текста на въпрос, жокер или друго поле.</p>

          <CopyToClipboard text={imageTag} />
        </>
      )}
    </div>
  );
};

export default Legend;
