export const inChunks = (array, chunk) => {
  return array.reduce((all, one, i) => {
    const ch = Math.floor(i / chunk);
    all[ch] = [].concat((all[ch] || []), one);

    return all
  }, [])
};

export const crumbsPerRow = (width) => {
  const desktop = 1024;
  const tablet = 600;

  if (width > desktop) {
    return 9
  } else if (width > tablet) {
    return 6
  } else {
    return 4
  }
};

export const paginateCrumbs = (questions) => {
  return inChunks(['home', ...questions, 'flag'], crumbsPerRow(window.innerWidth));
};
