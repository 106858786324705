import React from "react";
import Link from 'next/link'
import clsx from 'clsx'

import { NavLink } from "react-router-dom";

const baseStyles = {
  solid:
    'group inline-flex items-center justify-center rounded-full py-2 px-4 text-sm font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2',
  outline:
    'group inline-flex outline outline-1 items-center justify-center rounded-full py-2 px-4 text-sm focus:outline-none',
}

const variantStyles = {
  solid: {
    slate:
      'bg-neutral-900 text-white hover:bg-neutral-700 hover:text-neutral-100 active:bg-neutral-800 active:text-neutral-300 focus-visible:outline-neutral-900',
    blue: 'bg-primary-600 text-white hover:text-neutral-100 hover:bg-primary-500 active:bg-primary-800 active:text-primary-100 focus-visible:outline-primary-600',
    white:
      'bg-white text-neutral-900 hover:bg-primary-50 active:bg-primary-200 active:text-neutral-600 focus-visible:outline-white',
  },
  outline: {
    slate:
      'outline-neutral-300 text-neutral-700 hover:text-neutral-900 hover:outline-neutral-400 active:bg-neutral-100 active:text-neutral-600 focus-visible:outline-primary-600 focus-visible:outline-neutral-300',
    white:
      'outline-neutral-700 text-white hover:outline-neutral-500 active:outline-neutral-700 active:text-neutral-400 focus-visible:outline-white',
  },
}

export function Button({ variant, color, className, ...props }) {
  variant = variant ?? 'solid'
  color = color ?? 'slate'

  className = clsx(
    baseStyles[variant],
    variantStyles[variant][color],
    className,
  )

  return typeof props.href === 'undefined' ? (
    <button className={className} {...props} />
  ) : (
    <NavLink className={className} to={props.href} {...props} />
  )
}
