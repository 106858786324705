import React, { Fragment } from "react";
import MyMath from "./MyMath";
import { Popover, Transition } from '@headlessui/react'
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/20/solid'

export default function Flyout({
  open,
  setOpen,
  title,
  object
}) {
  return (
    <Popover className="relative isolate z-10 shadow">
      <div className="bg-white py-5">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <Popover.Button onClick={() => setOpen(!open)} className={`inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-gray-900 outline-none w-full truncate ${open ? 'underline' : ''}`}>
            <div className="max-w-[95%] truncate">
              <MyMath text={title}/>
            </div>
            {open && (<ChevronUpIcon className="h-5 w-5" aria-hidden="true" />)}
            {!open && (<ChevronDownIcon className="h-5 w-5" aria-hidden="true" />)}
          </Popover.Button>
        </div>
      </div>

      {open && (
        <Transition
          as={Fragment}
          show={open}
          enter="transition ease-out duration-200"
          enterFrom="opacity-0 -translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-1"
        >
          <Popover.Panel static className="inset-x-0 top-0 -z-10 bg-white shadow-lg">
            {object}
          </Popover.Panel>
        </Transition>
      )}
    </Popover>
  )
}
