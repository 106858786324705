import React from "react";

import { RadioGroup } from '@headlessui/react'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const enabledClasses = 'cursor-pointer text-neutral-900 hover:bg-neutral-50'
const disabledClasses = 'text-neutral-300 cursor-default'

// a value is {name: 'classic', enabled: true, id: uuid(), label: "Класически"}
const RadioChoice = ({
  value,
  setValue,
  allValues,
  title,
  subtitle
}) => {
  return (
    <div>
      <div className="flex gap-1">
        <h3 className="text-base font-semibold leading-6 text-neutral-900 leading-6 text-neutral-900 text-sm">{title}</h3>
      </div>

      <div className="my-1 max-w-l text-sm text-neutral-500">
        <p>{subtitle}</p>
      </div>
      <div>
        <RadioGroup value={value} onChange={setValue} className="mt-4">
          <div className="grid w-full gap-1.5 grid-cols-5">
            {allValues.map((availableValue) => (
              <RadioGroup.Option
                key={availableValue.id}
                value={availableValue.name}
                disabled={!availableValue.enabled}
                className={({ active, checked }) =>
                  classNames(
                    'text-center focus:outline-none',
                    active ? 'ring-2 ring-primary-700 ring-offset-2' : '',
                    checked
                      ? 'bg-primary-700 text-white hover:bg-primary-700/[.95]'
                      : 'ring-1 ring-inset ring-neutral-300 bg-white',
                    availableValue.enabled ?  enabledClasses : disabledClasses,
                    'flex items-center justify-center rounded-md py-2.5 px-0.5 text-sm font-medium sm:flex-1'
                  )
                }
              >
                <RadioGroup.Label as="span">{availableValue.label}</RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </div>
  );
};

export default RadioChoice;
