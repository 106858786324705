import axios from "axios";

const fetchTest = (id) => axios.get(`api/v1/tests/${id}`);
const fetchMyTests = () => axios.get('api/v1/test_takings');
const submitAnswers = (id, results) => axios.post(`api/v1/tests/${id}/submit_answers`, results);
const selfAssessQuestion = (testTakingId, questionId, isCorrect, points = null) => axios.post(`api/v1/test_takings/${testTakingId}/self_assess_question`, {
  assessment: {questionId: questionId, isCorrect: isCorrect, points: points}
});
const selfAssessSubquestion = (testTakingId, questionId, subquestionId, isCorrect, points = null) => axios.post(`api/v1/test_takings/${testTakingId}/self_assess_subquestion`, {
  assessment: {questionId: questionId, subquestionId: subquestionId, isCorrect: isCorrect, points: points}
});

const testsApi = {
  fetchTest,
  fetchMyTests,
  submitAnswers,
  selfAssessQuestion,
  selfAssessSubquestion
};

export default testsApi;
