import React, { useState } from "react";
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";
import { IoIosArrowDown } from "react-icons/io";
import { NavLink, useLocation } from "react-router-dom";

const SubMenu = ({ data }) => {
  const { pathname } = useLocation();
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  return (
    <>
      <li
        className={`flex flex-row items-center link w-full ${pathname.includes(data.name) && "text-primary-600"}`}
        onClick={() => setSubMenuOpen(!subMenuOpen)}
      >
        <data.icon size={23} className="w-10 inline pr-4" />
        <p className="flex-1 capitalize">{data.name}</p>
        <IoIosArrowDown
          className={` ${subMenuOpen && "transform rotate-180"} duration-200 `}
        />
      </li>
      <motion.ul
        animate={
          subMenuOpen
            ? {
                height: "fit-content",
              }
            : {
                height: 0,
              }
        }
        className="flex flex-col pl-10 text-[0.8rem] font-normal overflow-hidden"
      >
        {subMenuOpen && (data.menus?.map((menu) => (
            <li key={menu.path} onClick={menu.onClick}>
              <NavLink
                to={`/${data.path}/${menu.path}`}
                className="p-2 inline-block link w-full !bg-transparent capitalize"
              >
                {menu.label}
              </NavLink>
            </li>
          )))}
      </motion.ul>
    </>
  );
};

export default SubMenu;
