import React from "react";

import { CallToAction } from './components/CallToAction'
import { Faqs } from './components/Faqs'
import { Footer } from './components/Footer'
import { Header } from './components/Header'
import { Hero } from './components/Hero'
import { Pricing } from './components/Pricing'
import { PrimaryFeatures } from './components/PrimaryFeatures'
import { SecondaryFeatures } from './components/SecondaryFeatures'
import { Testimonials } from './components/Testimonials'

import MyScrollToTop from "components/commons/MyScrollToTop";

export default function PublicHome() {
  return (
    <>
      <main>
        <div id="homeBackgroundVideo" className="relative">
          <video loop autoPlay playsInline muted loop className="absolute z-minus top-0 left-0 w-full h-full object-cover" poster="back3.jpg">
            <source src="v23.mp4" type="video/mp4" />
          </video>

          <Header />
          <Hero />
        </div>
        <PrimaryFeatures />
        <SecondaryFeatures />
        <CallToAction />
        <Testimonials />
        <Pricing />
        <Faqs />
      </main>
      <Footer />
      <MyScrollToTop />
    </>
  )
}
