import React from "react";
import ReactHtmlParser from 'react-html-parser';
import { MathJax, MathJaxContext } from "better-react-mathjax";

const config = {
  loader: { load: ["[tex]/html"] },
  tex: {
    packages: { "[+]": ["html"] },
    inlineMath: [["$", "$"]],
    displayMath: [["$$", "$$"]]
  }
};

const Mathable = ({
  content
}) => (
  <MathJaxContext config={config} version={3}>
    <MathJax dynamic inline>
      {content}
    </MathJax>
  </MathJaxContext>
);

export default Mathable;
