import React, { Fragment, useState, useEffect } from "react";

import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

export default function ResultPercentage ({
  correct,
  total,
  grade = null
}) {
  let percentage = Math.round((correct / total) * 100)

  return (
    <div className="animate-fadeQuick shadow-circular rounded-full bg-neutral-100 w-[168px] m-auto mb-7">
      <div className="shine-on-hover">
        <CircularProgressbarWithChildren
          value={percentage}
          styles={buildStyles({
            strokeLinecap: 'round',
            pathColor: `rgba(22, 163, 74, ${percentage / 100})`,
            textColor: '#f88',
            trailColor: '#d6d6d6',
            backgroundColor: '#3e98c7',
          })}
        >
          <img className="w-auto h-3 opacity-40 -mt-1" src="/eindigo-gray.svg" />
          {grade && (
            <>
              <p className="text-neutral-800/75 font-semibold mt-1">{grade.label}</p>
              <p className="text-neutral-700 text-5xl pb-1.5 font-bold">{grade.value}</p>
              <p className="text-neutral-600"><strong>{correct}/{total}</strong> точки</p>
            </>
          )}
          {!grade && (
            <>
              <p className="text-neutral-700 text-5xl p-1 font-bold">{percentage}%</p>
              <p className="text-neutral-600 italic"><strong>{correct}/{total}</strong> правилни</p>
              <p className="text-neutral-600 italic">отговора</p>
            </>
          )}
        </CircularProgressbarWithChildren>
      </div>
    </div>
  )
}
