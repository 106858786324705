import React from "react";
import Image from 'next/image'
import ReactHtmlParser from 'react-html-parser';

import { Container } from './Container'
import backgroundImage from '../images/background-faqs.jpg'

const faqs = [
  [
    {
      question: 'Какво точно е платформата Elenor?',
      answer:
        '<u><a href="#examples">База от тестове по учебната програма</a></u>. Учениците могат да я използват както за допълнителна подготовка, така и за развлечение в свободното си време.',
    },
    {
      question: 'Как мога да използвам платформата?',
      answer: 'В момента сме в етап на създаване на максимално обемна и информативна база от тестове. Може да се запишете в <u><a href="./waitlist">тестова фокус група</a></u> за ранен достъп.',
    },
    {
      question: 'Платформата подходяща ли е за подготовка за НВО?',
      answer:
        'Да, един от основните ни приоритети е тестовете в платформата да помагат при подготовка за изпитите по Национално външно оценяване (НВО).',
    },
  ],
  [
    {
      question:
        'За деца на каква възраст е платформата?',
      answer:
        'Elenor e подходящ за ученици от 4-ти до 12-ти клас.',
    },
    {
      question: 'Освен тестове, предлагате ли уроци?',
      answer:
        'Не. Често въпросите включват помощни жокери, които цитират релевантна част от уроците. Това помага за попълване на липсващи знания на момента.<br><br>Но целта на Elenor е да покрие учебния материал с тестове и да надгражда над вече преподаваните уроци в училище, а не да ги преподава отново.',
    },
    {
      question:
        'Какво означава "развлекателни" тестове?',
      answer:
        'Включваме две категории тестове - Задължителни и Развлекателни.<br><br>Задължителните тестове са директно по учебните уроци, наподобяват изпит или контролна работа. Развлекателните тестове са по-общи и целят да забавляват, често имат особен формат и наподобяват игра.',
    },
  ],
  [
    {
      question: 'Какви са цените?',
      answer:
        'Абонаментът предлага пълен достъп до платформата, като ценовите планове се различават единствено по тяхната дължина. Може да разгледате подробно <u><a href="#pricing">тук</a></u>.',
    },
    {
      question: 'Мога ли да ползвам платформата безплатно?',
      answer: 'Да. Част от тестовете са безплатни и не изискват активен абонамент. За повече информация може да се запишете в <u><a href="./waitlist">тестова фокус група</a></u> за ранен достъп.',
    },
    {
      question: 'Кои предмети включва платформата?',
      answer:
        'Фокусът ни е върху Математика, Български език и литература и Английски език.',
    },
  ],
]

export function Faqs() {
  return (
    <section
      id="faq"
      aria-labelledby="faq-title"
      className="relative overflow-hidden bg-neutral-50 py-20 sm:py-32"
    >
      <img
        className="absolute left-1/2 top-0 max-w-none -translate-y-1/4 translate-x-[-30%]"
        src={backgroundImage}
        alt=""
        width={1558}
        height={946}
      />
      <Container className="relative">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2
            id="faq-title"
            className="font-display text-3xl tracking-tight text-neutral-900 sm:text-4xl"
          >
            Въпроси и отговори
          </h2>
          <p className="mt-4 text-lg tracking-tight text-neutral-700">
            Разгледайте кратки отговори на основните въпроси, които получаваме за платформата до момента. За допълнителни въпроси може да се свържете директно с нас на имейл <i>info@elenor.bg</i>.
          </p>
        </div>
        <ul
          role="list"
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-8 lg:max-w-none lg:grid-cols-3"
        >
          {faqs.map((column, columnIndex) => (
            <li key={columnIndex}>
              <ul role="list" className="flex flex-col gap-y-8">
                {column.map((faq, faqIndex) => (
                  <li key={faqIndex}>
                    <h3 className="font-display text-lg leading-7 text-neutral-900">
                      {faq.question}
                    </h3>
                    <p className="mt-4 text-sm text-neutral-700">{ReactHtmlParser(faq.answer)}</p>
                  </li>
                ))}
              </ul>
            </li>
          ))}
        </ul>
      </Container>
    </section>
  )
}
