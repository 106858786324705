import axios from "axios";

const fetch = () => axios.get("api/v1/questions");
const submitAnswers = payload => axios.post("api/v1/questions/submit_answers", payload);
const fetchHistory = () => axios.get("api/v1/questions/history");
// const fetchSubjects = () => axios.get("api/v1/subjects");

const questionsApi = {
  fetch,
  submitAnswers,
  fetchHistory,
  // fetchSubjects
};

export default questionsApi;
