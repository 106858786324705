import React from "react";
import ReactHtmlParser from 'react-html-parser';
import { DebounceInput } from 'react-debounce-input';

const renderInput = (entity, label, subtitle, rows, value, onChange) => {
  return (
    <div>
      <label htmlFor={entity} className="text-base font-semibold text-neutral-700">
        {label}
      </label>
      {subtitle.length > 0 && (
        <p className="text-sm text-neutral-500">{ReactHtmlParser(subtitle)}</p>
      )}
      <div className="mt-2">
        <DebounceInput
          element="textarea"
          debounceTimeout={500}
          onChange={onChange}
          id={entity}
          name={entity}
          rows={rows}
          className="block w-full rounded-md border-0 py-1.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-primary-600 sm:text-sm sm:leading-6"
          value={value}
        />
      </div>
    </div>
  )
}

const renderRadio = (field, title, subtitle, opts, onChange) => {
  return (
    <div>
      <label className="text-base font-semibold text-neutral-700">{title}</label>
      {subtitle.length > 0 && (
        <p className="text-sm text-neutral-500">{subtitle}</p>
      )}
      <fieldset className="mt-4">
        <div className="space-y-2">
          {opts.map((option) => (
            <div key={option.id} className="flex items-center">
              <input
                onChange={onChange}
                id={option.id}
                value={option.id}
                name={title}
                type="radio"
                checked={field === option.id}
                className="h-4 w-4 border-neutral-300 text-primary-600 focus:ring-primary-600 cursor-pointer"
              />
              <label htmlFor={option.id} className="ml-3 block text-sm font-medium leading-6 text-neutral-900 cursor-pointer">
                {option.title}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    </div>
  )
}

export { renderInput, renderRadio };
