import React from "react";
import ReactHtmlParser from 'react-html-parser';
// import { MathJax, MathJaxContext } from "better-react-mathjax";

// const config = {
//   loader: { load: ["[tex]/html"] },
//   tex: {
//     packages: { "[+]": ["html"] },
//     inlineMath: [["$", "$"]],
//     displayMath: [["$$", "$$"]]
//   }
// };

const MyMath = ({
  text
}) => (
  <>
    {text?.includes('$') ? text : ReactHtmlParser(text)}
  </>
);

export default MyMath;
