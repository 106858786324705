import React from "react";

import { RadioGroup } from '@headlessui/react'
import Tooltip from "components/commons/Tooltip";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const enabledClasses = 'cursor-pointer text-neutral-900 hover:bg-neutral-50'
const disabledClasses = 'text-neutral-300 cursor-default'

const GradeFilter = ({
  grade,
  setGrade,
  allGrades
}) => {
  return (
    <>
      <div className="flex gap-1.5">
        <h3 className="text-base font-semibold leading-6 text-neutral-900">Изберете клас</h3>

        {!grade && (
          <Tooltip
            text="Задължително поле"
            classes="-mt-24 text-center"
            object={
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#22d3ee" className="w-6 h-6 text-primary-700">
                <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
              </svg>
            }
          />)}
      </div>

      <div className="mt-2 max-w-l text-sm text-neutral-500">
        <p>
          Започнете търсенето чрез избор на един от изброените класове.
        </p>
      </div>
      <div className="mt-5">
      </div>
      <div>
        <RadioGroup value={grade} onChange={setGrade} className="mt-2">
          <div className="grid w-full gap-1.5 grid-cols-3 md:grid-cols-9">
            {allGrades.map((availableGrade) => (
              <RadioGroup.Option
                key={availableGrade.id}
                value={availableGrade.id}
                disabled={!availableGrade.enabled}
                className={({ active, checked }) =>
                  classNames(
                    'focus:outline-none',
                    active ? 'ring-2 ring-primary-700 ring-offset-2' : '',
                    checked
                      ? 'bg-primary-700 text-white hover:bg-primary-700/[.95]'
                      : 'ring-1 ring-inset ring-neutral-300 bg-white',
                    availableGrade.enabled ?  enabledClasses : disabledClasses,
                    'flex items-center justify-center rounded-md py-2.5 px-0.5 text-sm font-medium sm:flex-1'
                  )
                }
              >
                <RadioGroup.Label as="span">{availableGrade.title}</RadioGroup.Label>
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </>
  );
};

export default GradeFilter;
