import React, { Fragment, useState, useEffect } from "react";

import { Transition } from '@headlessui/react'
import { useTimeoutFn } from 'react-use'

export default function Spinnable ({
  classes = '',
  content
}) {
  let [isShowing, setIsShowing] = useState(false)
  let [, , resetIsShowing] = useTimeoutFn(() => setIsShowing(true), 300)

  useEffect(() => {
    resetIsShowing()
  })

  return (
    <div className={'flex flex-col items-center ' + classes}>
      <Transition
        as={Fragment}
        show={isShowing}
        enter="transform transition duration-[400ms]"
        enterFrom="opacity-0 rotate-[-120deg] scale-50"
        enterTo="opacity-100 rotate-0 scale-100"
        leave="transform duration-200 transition ease-in-out"
        leaveFrom="opacity-100 rotate-0 scale-100 "
        leaveTo="opacity-0 scale-95 "
      >
        {content}
      </Transition>
    </div>
  )
}
