import React from "react";
import { NavLink } from "react-router-dom";

import { SUBSCRIPTION_PATH } from "components/routeConstants";

export default function GoPremium({
  title,
  subtitle
}) {
  return (
    <div className="bg-neutral-50 shadow sm:rounded-lg mt-4 mb-20 md:max-w-xl">
      <div className="px-4 py-5 sm:p-6">
        <h3 className="text-base font-semibold leading-6 text-neutral-900">{title}</h3>
        <div className="mt-2 max-w-l text-sm text-neutral-500">
          <p>
            {subtitle}
          </p>
        </div>
        <div className="mt-5">
        </div>

        <NavLink
          to={SUBSCRIPTION_PATH}
          className="mx-auto block rounded-lg"
        >
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-primary-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500"
          >
            Към абонаментите
          </button>
        </NavLink>
      </div>
    </div>
  )
}
