import React, { useState, useEffect } from "react";
import { uuid } from "utils/uuid";
import { displayableGoogleDriveImageLink } from "utils/googleDrive";
import { setToLocalStorage, getFromLocalStorage } from "utils/storage";

import { renderInput, renderRadio } from "./helpers";
import DownloadJSON from "./DownloadJSON";
import QuestionGenerator from "./QuestionGenerator";
import Legend from "./Legend";
import Classic from "../Test/Classic";

import { MagnifyingGlassIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { ArrowUpCircleIcon } from '@heroicons/react/20/solid'
import { PlusIcon } from '@heroicons/react/20/solid'
import Mathable from "components/commons/Mathable";
import MyScrollToTop from "components/commons/MyScrollToTop";

function emptyQuestion() {
  return {id: uuid()}
}

const TestGenerator = () => {
  const [testTitle, setTestTitle] = useState('')
  const [testIntro, setTestIntro] = useState('')
  const [testImage, setTestImage] = useState('')
  const [testDescription, setTestDescription] = useState('')
  const [premiumness, setPremiumness] = useState('free')
  const [category, setCategory] = useState('mandatory')
  const [timeForTest, setTimeForTest] = useState(60)
  const [questions, setQuestions] = useState([emptyQuestion()])
  const [showPreview, setShowPreview] = useState(false)

  useEffect(() => {
    if (questions.length > 1) {
      setToLocalStorage("currentTestCreationProgress", serialize());
    }
  }, [questions]);

  function saveQuestion(question) {
    const nextQuestions = questions.map((q) => {
      if (q.id === question.id) {
        return question;
      } else {
        return q;
      }
    });

    setQuestions(nextQuestions);

    // if (showPreview) {
    //   setShowPreview(false)
    // }
    // setTimeout(() => {
    //   setShowPreview(true)
    // }, '1');
  }

  function deleteQuestion(question) {
    if (confirm('Сигурни ли сте, че искате да изтриете въпроса?')) {
      const remaining = questions.filter(q => q.id !== question.id);

      const nextQuestions = remaining.map((q) => {
        if (q.index > question.index) {
          return {
            ...q,
            index: q.index - 1
          };
        } else {
          return q;
        }
      });

      setShowPreview(false)
      setQuestions(nextQuestions);
    }
  }

  function title() {
    return renderInput('title', 'Име на теста', '', 1, testTitle, e => setTestTitle(e.target.value))
  }

  function intro() {
    return renderInput('intro', 'Въвеждащи думи', 'Показва се под заглавието на тест страницата, може да е няколко реда.', 3, testIntro, e => setTestIntro(e.target.value))
  }

  function image() {
    return renderInput('image', '🖌️ Изображение', 'Показва се отгоре на теста. Поставете линк към изображение от папката <u><a class="text-sky-700" href="https://drive.google.com/drive/folders/1kqdl547yCu58Ci2w7KE7QmWmCuOWeNNe?usp=drive_link" target="_blank">Еленор</a></u>.', 1, testImage, e => setTestImage(displayableGoogleDriveImageLink(e.target.value)))
  }

  function description() {
    return renderInput('description', 'Описание', 'Показва се под заглавията на списък от тестове, <b>трябва да e един ред</b>.', 1, testDescription, e => setTestDescription(e.target.value))
  }

  function testCategory() {
    const opts = [{ id: 'mandatory', title: 'Задължителен' }, { id: 'casual', title: 'Развлекателен' }]
    return renderRadio(category, 'Категория', '', opts, e => setCategory(e.target.value))
  }

  function isTestFree() {
    const opts = [{ id: 'free', title: 'Безплатен' }, { id: 'paid', title: 'Платен' }]
    return renderRadio(premiumness, 'Безплатен или платен', 'Трябва ли да има платен абонамент за да се види теста.', opts, e => setPremiumness(e.target.value))
  }

  function setTestFromPayload(content) {
    setCategory(content['category'] || '')
    setTestTitle(content['title'] || '')
    setTestIntro(content['intro'] || '')
    setTestImage(content['image_url'] || '')
    setTestDescription(content['description'] || '')
    setPremiumness(content['free'] === true ? 'free' : 'paid')
    setTimeForTest(content['time_for_test'] || 60)
    setQuestions(content['questions'] || [emptyQuestion()])
  }

  const handleFileUpload = e => {
    const fileReader = new FileReader();
    fileReader.readAsText(e.target.files[0], "UTF-8");
    fileReader.onload = e => {
      const content = JSON.parse(e.target.result)['test'];
      setTestFromPayload(content);
    };
  };

  function restoreFromLocalStorage() {
    const content = getFromLocalStorage("currentTestCreationProgress").test
    setTestFromPayload(content);
  }

  function fileUpload() {
    return (
      <div className="bg-neutral-100 p-5 rounded-md">
        <label htmlFor="file-upload" className="text-base font-semibold text-neutral-700">Продължете от файл</label>
        <p className="text-sm text-neutral-500 pb-4">Довършете теста от вече генериран <i>.json</i> файл.</p>
        <input id="file-upload" type="file" className="block w-full text-sm text-neutral-500 file:me-4 file:py-2 file:px-4 file:rounded file:border-0 file:text-sm file:font-semibold file:bg-primary-600 file:text-white hover:file:bg-primary-700 file:disabled:opacity-50 file:disabled:pointer-events-none file:cursor-pointer" onChange={handleFileUpload} />

        <div className="mt-3">
          <p>или <button onClick={restoreFromLocalStorage} className="px-1 py-1.5 hover:text-neutral-950 hover:bg-neutral-200 rounded"> Възстанови последно запазена версия </button></p>
        </div>
      </div>
    )
  }

  function addQuestion() {
    setShowPreview(false)
    setQuestions([...questions, emptyQuestion()])
  }

  function renderPreviewButton() {
    const label = showPreview ? 'Скрий превю' : 'Покажи превю'

    return (
      <button onClick={() => setShowPreview(!showPreview)} type="button" className="inline-flex items-center gap-x-1.5 rounded-md bg-primary-50 px-3 py-2 text-sm font-semibold text-primary-600 shadow-sm hover:bg-primary-100 w-fit m-auto">
        {showPreview && (<ArrowUpCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />)}
        {!showPreview && (<MagnifyingGlassIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />)}
        {label}
      </button>
    )
  }

  function indexOf(question) {
    if (typeof question.index === 'string') {
      return question.index;
    }

    const indexed_questions = questions.filter((q) => typeof q.index === 'number' || q.index == null)
    return Math.max(indexed_questions.indexOf(question), 0) + 1
  }

  function idLookup(questionIndex) {
    if (questionIndex == null) {
      return;
    }
    return questions.find((q) => q.index === questionIndex)?.id;
  }

  function availableToDouble(forQuestion) {
    const initial = questions.filter((q) => q.id !== forQuestion.id)
    const doubled = initial.filter((q) => typeof indexOf(q) === 'string').map((q) => Number(indexOf(q).slice(0, -1)));
    const available = initial.filter((q) => typeof indexOf(q) !== 'string' && !doubled.includes(indexOf(q)));

    return available.map(function(q) {
      return {id: q.id, name: indexOf(q)};
    })
  }

  function testDuration() {
    return (
      <div>
        <label htmlFor="time-for-test-number" className="text-base font-semibold text-neutral-700">
          Време за решение на теста
        </label>
        <div className="flex gap-2 mt-3">
          <div>
            <input value={timeForTest} onChange={e => setTimeForTest(e.target.value)} type="number" name="number" min="1" max="600" id="time-for-test-number" className="block my-auto w-20 rounded-md border-0 py-1.5 text-neutral-900 shadow-sm ring-1 ring-inset ring-neutral-300 placeholder:text-neutral-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
          </div>
          <label htmlFor="time-for-test-number" className="block my-auto text-sm font-medium leading-6 text-neutral-900">
            минути
          </label>
        </div>
      </div>
    )
  }

  function totalPointsSum() {
    return questions.map((q) => q.is_double ? 0 : parseInt(q.points || 0)).reduce((partialSum, a) => partialSum + a, 0);
  }

  function totalPointsPreview() {
    return (
      <div className="rounded-md bg-blue-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <InformationCircleIcon aria-hidden="true" className="h-5 w-5 text-blue-400" />
          </div>
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">
              Общ брой точки на теста: <b>{totalPointsSum()} <span className="italic font-light text-xs"> (брои само запазените въпроси)</span></b>
            </p>
          </div>
        </div>
      </div>
    )
  }

  function questionsSection() {
    return (
      <div>
        <div className="text-base font-semibold text-neutral-700">Въпроси</div>
        <p className="text-sm text-neutral-500 pb-4">Запазете всеки въпрос след като сте готови с него.</p>
        {questions.map((question) => (
          <div key={question.id} className="border-b border-neutral-50">
            <Mathable content={
              <QuestionGenerator
                question={question}
                index={indexOf(question)}
                saveQuestion={saveQuestion}
                deleteQuestion={deleteQuestion}
                idLookup={idLookup}
                availableToDouble={availableToDouble}
              />
            }/>
          </div>
        ))}
        <div className="m-auto pb-4">
          <button onClick={addQuestion} type="button" className="inline-flex items-center gap-x-1.5 rounded-md bg-primary-50 px-3 py-2 text-sm font-semibold text-primary-600 shadow-sm hover:bg-primary-100 mt-7">
            <PlusIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Добави въпрос
          </button>
        </div>
      </div>
    )
  }

  function serialize() {
    return {
      test: {
        title: testTitle,
        intro: testIntro,
        image_url: (testImage.length > 0 ? testImage : null),
        description: testDescription,
        free: premiumness === 'free',
        kind: 'classic',
        visible: true,
        category: category,
        time_for_test: timeForTest,
        time_for_taking_in_seconds: timeForTest * 60,
        questions: questions
      }
    }
  }

  return (
    <div className="flex flex-col space-y-7 px-2 sm:px-4 py-5 sm:w-[650px] md:w-[650px] lg:w-[720px] xl:w-[800px] px-4 sm:px-6 lg:px-8 m-auto">
      {fileUpload()}
      {title()}
      {intro()}
      {image()}
      {description()}
      {testDuration()}
      <div className="flex gap-2 items-center justify-between">
        {testCategory()}
        {isTestFree()}
      </div>
      <Mathable content={<Legend />}/>
      {totalPointsPreview()}
      {questionsSection()}
      {renderPreviewButton()}
      {showPreview && <Mathable content={<Classic test={serialize().test} renderingFromAdminPreview={true}/>}/>}

      {testTitle.length > 0 && (
        <div className="bg-neutral-100 p-5 rounded-md">
          <div className="text-base font-semibold text-neutral-700">Генерирайте JSON файл</div>
          <p className="text-sm text-neutral-500 pb-4">Създайте <i>.json</i> файл, с който ще създадем теста в платформата.</p>
          <DownloadJSON data={serialize()} fileName={testTitle.replace(/\s+/g, '-').toLowerCase()}/>
        </div>
      )}
    <MyScrollToTop />
    </div>
  );
};

export default TestGenerator;
