import React from "react";
// import Link from 'next/link'
import { NavLink } from "react-router-dom";

export function NavLinkMy({ href, children }) {
  return (
    <NavLink
      to={href}
      className="inline-block rounded-lg px-2 py-1 text-sm text-neutral-700 hover:bg-neutral-100 hover:text-neutral-900"
    >
      {children}
    </NavLink>
  )
}
