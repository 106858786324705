import React, { useState } from 'react'
import Countdown from 'react-countdown';

export default function ClockCountdown({
  timeInSeconds,
  onCountdownOver
}) {
  const [startTime, setStartTime] = useState(Date.now())

  // can be used to know the percentage left
  //
  // onTick={onTick}
  //
  // function onTick(timeObject) {
  //   console.log('tick!', Math.round((timeObject.seconds / timeInSeconds) * 100))
  // }

  return (
    <Countdown
      date={startTime + timeInSeconds * 1000}
      daysInHours={true}
      onComplete={onCountdownOver}
    />
  )
}
