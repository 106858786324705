import axios from "axios";

const fetch = () => axios.get("api/v1/subjects");
const fetchGrade = (id, gradeId) => axios.get(`api/v1/subjects/${id}/grade`, {params: {grade: gradeId}});
const fetchGrades = (id) => axios.get(`api/v1/subjects/${id}/grades`);
const fetchTests = (id, params) => axios.get(`api/v1/subjects/${id}/tests`, {params: params});

const subjectsApi = {
  fetch,
  fetchGrade,
  fetchGrades,
  fetchTests
};

export default subjectsApi;
