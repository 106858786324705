import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";

import { useUserState } from "contexts/user";
import { HOME_PATH, DASHBOARD_PATH } from "components/routeConstants";

import TestGenerator from "./TestGenerator";

const Admin = () => {
  const { user } = useUserState();

  if (user.role != 'super_admin') {
    return (
      <Redirect from={DASHBOARD_PATH} to={HOME_PATH} />
    );
  }

  useEffect(() => {
    const onBeforeUnload = (ev) => {
      ev.returnValue = "Обновяване на страницата";
      return "Обновяване на страницата";
    };

    if (location.hostname !== "localhost") {
      window.addEventListener("beforeunload", onBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  return (
    <>
      <TestGenerator/>
    </>
  );
};

export default Admin;
