import React from "react";

export default function CopyToClipboard({
  text
}) {
  const copyClicked = () => {
    navigator.clipboard.writeText(text);
    showSuccess();

    setTimeout(() => {
      resetToDefault();
    }, 2000);
  }

  const showSuccess = () => {
    document.getElementById('copyToClipboard-default-message').classList.add('hidden');
    document.getElementById('copyToClipboard-success-message').classList.remove('hidden');
  }

  const resetToDefault = () => {
    document.getElementById('copyToClipboard-default-message').classList.remove('hidden');
    document.getElementById('copyToClipboard-success-message').classList.add('hidden');
  }

  return (
    <div className="w-full">
      <div className="relative">
        <label htmlFor="copyToClipboard-input" className="sr-only">
          Label
        </label>
        <input
          id="copyToClipboard-input"
          type="text"
          className="col-span-6 bg-neutral-50 border border-neutral-300 text-neutral-500 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full px-2.5 py-4 dark:bg-neutral-700 dark:border-neutral-600 dark:placeholder-neutral-400 dark:text-neutral-400 dark:focus:ring-primary-500 dark:focus:border-primary-500"
          defaultValue={text}
          disabled={true}
          readOnly={true}
        />
        <button
          onClick={copyClicked}
          data-copy-to-clipboard-target="copyToClipboard-input"
          className="absolute end-2.5 top-1/2 -translate-y-1/2 text-neutral-900 dark:text-neutral-400 hover:bg-neutral-100 dark:bg-neutral-800 dark:border-neutral-600 dark:hover:bg-neutral-700 rounded-lg py-2 px-2.5 inline-flex items-center justify-center bg-white border-neutral-200 border"
        >
          <span id="copyToClipboard-default-message" className="inline-flex items-center">
            <svg className="w-3 h-3 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 20" >
              <path d="M16 1h-3.278A1.992 1.992 0 0 0 11 0H7a1.993 1.993 0 0 0-1.722 1H2a2 2 0 0 0-2 2v15a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2Zm-3 14H5a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2Zm0-4H5a1 1 0 0 1 0-2h8a1 1 0 1 1 0 2Zm0-5H5a1 1 0 0 1 0-2h2V2h4v2h2a1 1 0 1 1 0 2Z" />
            </svg>
            <span className="text-xs font-semibold">Копирай</span>
          </span>
          <span id="copyToClipboard-success-message" className="hidden inline-flex items-center">
            <svg className="w-3 h-3 text-primary-700 dark:text-primary-500 me-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 12" >
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M1 5.917 5.724 10.5 15 1.5" />
            </svg>
            <span className="text-xs font-semibold text-primary-700 dark:text-primary-500">
              Копирано
            </span>
          </span>
        </button>
      </div>
    </div>
  )
}
