import React, { Fragment, useState, useEffect } from "react";

import { Transition } from '@headlessui/react'
import { useTimeoutFn } from 'react-use'

export default function LogoSpin ({
  classes = '',
  height = 'default'
}) {
  let [isShowing, setIsShowing] = useState(false)
  let [, , resetIsShowing] = useTimeoutFn(() => setIsShowing(true), 300)

  useEffect(() => {
    resetIsShowing()
  })

  return (
    <div className={'flex flex-col items-center ' + classes}>
      <div className={height == 'default' ? 'h-16 w-16' : (height == 'small' ? 'w-14 h-14' : 'w-12 h-12') }>
        <Transition
          as={Fragment}
          show={isShowing}
          enter="transform transition duration-[400ms]"
          enterFrom="opacity-0 rotate-[-120deg] scale-50"
          enterTo="opacity-100 rotate-0 scale-100"
          leave="transform duration-200 transition ease-in-out"
          leaveFrom="opacity-100 rotate-0 scale-100 "
          leaveTo="opacity-0 scale-95 "
        >
          <div className="h-full w-full p-2 rounded-lg shadow-subtle bg-white">
            <img
              className="shine-on-hover w-auto"
              src="/eindigo.svg"
              alt="Eleanor"
            />
          </div>
        </Transition>
      </div>
    </div>
  )
}
