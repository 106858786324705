import React from "react";

const Tooltip = ({
  text,
  object,
  classes
}) => (
  <div className="flex items-center">
    <div className="flex relative">
      <div className="peer">
        {object}
      </div>

      <span className={'hidden lg:block peer-hover:opacity-100 transition-opacity ease-linear duration-300 bg-neutral-500 px-2 py-0.5 text-sm text-white rounded-md absolute left-1/2 -translate-x-1/2 translate-y-full opacity-0 z-10 mx-auto ' + classes}>
        {text}
      </span>
    </div>
  </div>
);

export default Tooltip;
