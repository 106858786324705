const getIdFromGoogleDriveLink = (googleDriveLink) => {
  const match = googleDriveLink.match(/https:\/\/drive.google.com\/file\/d\/(.*)\/.*/);
  return match ? match[1] : ''
}

const displayableGoogleDriveImageLink = (googleDriveLink) => {
  const id = getIdFromGoogleDriveLink(googleDriveLink);
  return id ? `https://lh3.googleusercontent.com/d/${id}` : ''
}

export { displayableGoogleDriveImageLink };
