import React from "react";
import { NavLink } from "react-router-dom";

import { Form, Formik } from "formik";
import { Button } from "neetoui";
import { Input } from "neetoui/formik";
import PropTypes from "prop-types";

import authenticationApi from "apis/authentication";
import { LOGIN_PATH, DASHBOARD_PATH } from "components/routeConstants";

import {
  WAITLIST_FORM_INITIAL_VALUES,
  WAITLIST_FORM_VALIDATION_SCHEMA,
} from "./constants";

import LogoSpin from "components/commons/LogoSpin";

const handleSubmit = async formData => {
  try {
    const {
      data: {},
    } = await authenticationApi.joinWaitlist(formData);
    history.push(DASHBOARD_PATH);
  } catch (error) {
    logger.error(error);
  }
};

const WaitlistForm = () => (
  <div className="flex h-full md:h-screen w-screen flex-row items-center justify-center overflow-x-hidden p-6">
    <div className="mx-auto flex h-full w-full flex-col items-center justify-center sm:max-w-md">
      <NavLink to="/" className="mb-10 mt-24 sm:mt-1">
        <LogoSpin height="small" />
      </NavLink>
      <Formik
        initialValues={WAITLIST_FORM_INITIAL_VALUES}
        validationSchema={WAITLIST_FORM_VALIDATION_SCHEMA}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form className="neeto-ui-rounded-md neeto-ui-bg-white neeto-ui-shadow-s w-full space-y-6 border p-6 sm:p-10">
            <h2 className="neeto-ui-text-neutral-800 mb-5 text-center text-3xl font-extrabold py-2">
              Запиши се за достъп
            </h2>

            <div className="flex gap-2">
              <span className="text-primary-700">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M18 18.72a9.094 9.094 0 003.741-.479 3 3 0 00-4.682-2.72m.94 3.198l.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0112 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 016 18.719m12 0a5.971 5.971 0 00-.941-3.197m0 0A5.995 5.995 0 0012 12.75a5.995 5.995 0 00-5.058 2.772m0 0a3 3 0 00-4.681 2.72 8.986 8.986 0 003.74.477m.94-3.197a5.971 5.971 0 00-.94 3.197M15 6.75a3 3 0 11-6 0 3 3 0 016 0zm6 3a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zm-13.5 0a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z" />
                </svg>
              </span>
              <p>
                Включи се в <strong>тестова фокус група</strong>, в която ще може да изпробваш платформата <strong><i>Еленор</i></strong> преди да бъде отворена публично.
              </p>
            </div>

            <div className="flex gap-2">
              <span className="text-primary-700">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                </svg>
              </span>
              <p>За препоръки <a  className="text-primary-600 hover:text-primary-700 font-medium" href="https://forms.gle/r79aZ3GprYCtWGHb6" target="_blank">попълни формата</a>.</p>
            </div>
            <div className="flex gap-2">
              <span className="text-primary-700">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                </svg>
              </span>
              <p>Всички участници във фокус групата ще получат <strong>един месец безплатен достъп</strong> след старта, при регистрация с оставения имейл адрес.</p>
            </div>

            <Input
              required
              label="Имейл"
              name="email"
              placeholder=""
              type="email"
            />
            <Button
              fullWidth
              className="h-9 bg-primary-600 hover:bg-primary-700"
              disabled={isSubmitting}
              label="Записване"
              loading={isSubmitting}
              size="small"
              type="submit"
            />
            <div className="flex flex-row items-center justify-start space-x-1">
              {/*<p className="neeto-ui-text-neutral-600 font-normal">
                Вече имате регистрация?
              </p>*/}
              <Button label="Влез като администратор" size="small" style="link" to={LOGIN_PATH} className="m-auto text-primary-600 hover:text-primary-700" />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  </div>
);

const Waitlist = ({ history }) => {
  return (
    <div className="relative">
      <video loop autoPlay playsInline muted loop className="absolute z-minus top-0 left-0 w-full h-full object-cover">
        <source src="w1.mp4" type="video/mp4" />
      </video>

      {WaitlistForm()}
    </div>
  );
};

Waitlist.propTypes = {
  history: PropTypes.object,
};

export default Waitlist;
