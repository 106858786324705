import React, { useEffect, useState, useRef } from "react";
import SubMenu from "./SubMenu";
import {AnimatePresence, motion} from "framer-motion/dist/framer-motion";

// * React icons
import { IoIosArrowBack } from "react-icons/io";
import { SlSettings } from "react-icons/sl";
import { AiOutlineAppstore } from "react-icons/ai";
import { AiOutlineCheckCircle } from "react-icons/ai";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { useMediaQuery } from "react-responsive";
import { MdMenu } from "react-icons/md";
import { NavLink, useLocation, useRoutes } from "react-router-dom";

import authenticationApi from "apis/authentication";
import { LOGIN_PATH } from "components/routeConstants";
import { useAuthDispatch } from "contexts/auth";
import { useUserState } from "contexts/user";

const Sidebar = () => {
  const authDispatch = useAuthDispatch();
  const handleLogout = async () => {
    try {
      await authenticationApi.logout();
      authDispatch({ type: "LOGOUT" });
    } catch (error) {
      logger.error(error);
    }
  };

  let isTabletMid = useMediaQuery({ query: "(max-width: 768px)" });
  const [open, setOpen] = useState(isTabletMid ? false : true);
  const sidebarRef = useRef();
  const { pathname } = useLocation();

  useEffect(() => {
    if (isTabletMid) {
      setOpen(false);
    } else {
      setOpen(true);
    }
  }, [isTabletMid]);

  useEffect(() => {
    isTabletMid && setOpen(false);
  }, [pathname]);

  const Nav_animation = isTabletMid
    ? {
        open: {
          x: 0,
          width: "16rem",
          transition: {
            damping: 40,
          },
        },
        closed: {
          x: -250,
          width: 0,
          transition: {
            damping: 40,
            delay: 0.05,
          },
        },
      }
    : {
        open: {
          width: "16rem",
          transition: {
            damping: 40,
          },
        },
        closed: {
          width: "3.2rem",
          transition: {
            damping: 40,
          },
        },
      };

  const subMenusList = [
    {
      name: "Настройки",
      path: "settings",
      icon: SlSettings,
      menus: [
        {
          path: "profile",
          label: "Профил"
        }, {
          path: "email",
          label: "Имейл"
        }, {
          path: "password",
          label: "Парола"
        }, {
          path: LOGIN_PATH,
          label: "Излез",
          onClick: handleLogout,
      }]
    }
  ];

  return (
    <>
      <div className={`${isTabletMid && "absolute z-10 w-screen"}`}>
        <div
          onClick={() => setOpen(false)}
          className={`md:hidden fixed inset-0 max-h-screen z-[998] bg-gradient-to-r from-neutral-800 to-neutral-200 opacity-60 ${
            open ? "block" : "hidden"
          } `}
        ></div>
        <motion.div
          ref={sidebarRef}
          variants={Nav_animation}
          initial={{ x: isTabletMid ? -250 : 0 }}
          animate={open ? "open" : "closed"}
          className="bg-white text-gray shadow-xl z-10 z-[999] max-w-[16rem]  w-[16rem] overflow-hidden md:relative fixed h-screen"
        >
          <div className="flex items-center gap-2.5 font-medium border-b py-3 border-neutral-300 mx-3">
            <img src="https://img.icons8.com/color/512/firebase.png"width={45} alt=""/>
            <span className="text-xl whitespace-pre">Eleanor</span>
          </div>

          <div className="flex flex-col h-full">
            <ul className="whitespace-pre px-3.5 text-[0.9rem] py-5 flex flex-col gap-3 font-medium overflow-x-hidden scrollbar-thin scrollbar-track-white scrollbar-thumb-neutral-100   md:h-[68%] h-[70%]">
              <li className="flex flex-row items-center space-x-3">
                <NavLink to={"/home"} className="link w-full">
                  <AiOutlineAppstore size={23} className="w-10 inline pr-4" />
                  Начало
                </NavLink>
              </li>
              <li className="flex flex-row items-center space-x-3">
                <NavLink to={"/tests"} className="link w-full">
                  <AiOutlineCheckCircle size={23} className="w-10 inline pr-4" />
                  Тестове
                </NavLink>
              </li>
              <li className="flex flex-row items-center space-x-3">
                <NavLink to={"/questions"} className="link w-full">
                  <BsFillPersonCheckFill size={23} className="w-10 inline pr-4" />
                  Въпроси
                </NavLink>
              </li>
              {(open || isTabletMid) && (
                <div>
                  {subMenusList?.map((menu) => (
                    <div key={menu.name} className="flex flex-col cursor-pointer">
                      <SubMenu data={menu} />
                    </div>
                  ))}
                </div>
              )}
            </ul>
          </div>
          <motion.div
            onClick={() => {
              setOpen(!open);
            }}
            animate={
              open
                ? {
                    x: 0,
                    y: 0,
                    rotate: 0,
                  }
                : {
                    x: 0,
                    y: 0,
                    rotate: 180,
                  }
            }
            transition={{ duration: 0 }}
            className="absolute w-fit h-fit md:block z-50 hidden right-2 bottom-3 cursor-pointer"
          >
            <IoIosArrowBack size={25} />
          </motion.div>
        </motion.div>
          <div className="p-4 bg-neutral-100 w-full md:hidden">
            <MdMenu size={25} className="absolute cursor-pointer" onClick={() => setOpen(true)}/>
            <div className="w-full flex justify-center gap-1">
              <img src="https://img.icons8.com/color/512/firebase.png"width={25} alt=""/>
              <span className="text-xl whitespace-pre">Eleanor</span>
            </div>
          </div>
      </div>
    </>
  );
};

export default Sidebar;
