import React from "react";

const Divider = () => (
  <div className="relative">
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className="w-4/5 md:w-3/5 lg:w-1/2 m-auto border-t border-neutral-200" />
    </div>
    <div className="relative flex justify-center">
      <span className="bg-white px-2 text-neutral-200">
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="h-4 w-4 text-neutral-300">
          <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
        </svg>
      </span>
    </div>
  </div>
);

export default Divider;
