import React from "react";

import ReactHtmlParser from 'react-html-parser';
import { useUserState } from "contexts/user";
import { CheckIcon } from '@heroicons/react/20/solid'

const tiers = [
  {
    name: 'Шест месеца',
    id: 'tier-freelancer',
    price: '19.99 лв.',
    length: 'месец',
    lookupKey: 'SIX_MONTHS',
    description: 'Най-краткият наличен план. Подходящ за първо ползване на платформата.',
    features: [
      'Плащате <u><strong>119.94 лв. на всеки 6 месеца</strong></u>',
      'Пълен достъп до платформата',
      'Достъп до бъдещо съдържание',
      'Плащане чрез Stripe'
    ],
    mostPopular: false,
  },
  {
    name: 'Една година',
    id: 'tier-monthly',
    price: '14.99 лв.',
    length: 'месец',
    lookupKey: 'YEARLY',
    description: 'Оптимален баланс между дължина на абонамента и цена.',
    features: [
      'Плащате <u><strong>179.88 лв. на година</strong></u>',
      'Пълен достъп до платформата',
      'Достъп до бъдещо съдържание',
      'Плащане чрез Stripe'
    ],
    mostPopular: true,
  },
  {
    name: 'Неограничен достъп',
    id: 'tier-enterprise',
    price: '299.99 лв.',
    length: 'еднократно',
    lookupKey: 'LIFETIME',
    description: 'Най-изгодният вариант ако искате да използвате платформата без ограничение. ',
    features: [
      '<u><strong>Еднократно плащане от 299.99 лв.</strong></u>',
      'Безкрайна продължителност на акаунта',
      'Пълен достъп до платформата',
      'Достъп до бъдещо съдържание',
      'Плащане чрез Stripe'
    ],
    mostPopular: false,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Plans() {
  const { user } = useUserState();

  return (
    <div className="bg-white py-5 sm:py-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-primary-600">Планове</h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-neutral-900 sm:text-5xl">
            Абонаменти за пълен достъп
          </p>
        </div>
        <p className="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-neutral-600">
          Изберете абонаментен план и получете цялостен достъп до платформата. Възможни са планове от шест месеца, една година или неограничен.
        </p>
        <div className="isolate mx-auto mt-16 grid max-w-md grid-cols-1 gap-y-8 sm:mt-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier, tierIdx) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular ? 'lg:z-10 lg:rounded-b-none' : 'lg:mt-8',
                tierIdx === 0 ? 'lg:rounded-r-none' : '',
                tierIdx === tiers.length - 1 ? 'lg:rounded-l-none' : '',
                'flex flex-col justify-between rounded-3xl bg-white p-8 outline outline-1 outline-neutral-200 xl:p-10'
              )}
            >
              <div>
                <div className="flex items-center justify-between gap-x-4">
                  <h3
                    id={tier.id}
                    className={classNames(
                      tier.mostPopular ? 'text-primary-600' : 'text-neutral-900',
                      'text-xl font-semibold leading-8'
                    )}
                  >
                    {tier.name}
                  </h3>
                  {tier.mostPopular ? (
                    <p className="rounded-full bg-primary-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-primary-600">
                      Най-популярен
                    </p>
                  ) : null}
                </div>
                <p className="mt-4 text-sm leading-6 text-neutral-600">{tier.description}</p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-4xl font-bold tracking-tight text-neutral-900">{tier.price}</span>
                  <span className="text-sm font-semibold leading-6 text-neutral-600">/{tier.length}</span>
                </p>
                <ul role="list" className="mt-8 space-y-3 text-sm leading-6 text-neutral-600">
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon className="h-6 w-5 flex-none text-primary-600" aria-hidden="true" />
                      <span>{ReactHtmlParser(feature)}</span>
                    </li>
                  ))}
                </ul>
              </div>
              <form action="/stripe/create-checkout-session" method="POST">
                {/* hidden field with the lookup_key of the Price */}
                <input type="hidden" name="lookup_key" value={tier.lookupKey} />
                <input type="hidden" name="user_id" value={user.id} />

                <button id="checkout-and-portal-button" type="submit"
                  className={classNames(
                    tier.mostPopular
                      ? 'bg-primary-600 text-white shadow-sm hover:bg-primary-500'
                      : 'text-primary-600 ring-1 ring-inset ring-primary-200 hover:ring-primary-300',
                    'w-full mt-8 block rounded-md py-2 px-3 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600'
                  )}
                >
                    Активирай абонамент
                </button>
              </form>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
