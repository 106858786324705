import * as yup from "yup";

export const LOGIN_FORM_INITIAL_VALUES = {
  email: "",
  password: "",
};

export const RESET_PASSWORD_FORM_INITIAL_VALUES = {
  email: "",
};

export const SIGNUP_FORM_INITIAL_VALUES = {
  email: "",
  firstName: "",
  lastName: "",
  password: "",
  passwordConfirmation: "",
};

export const WAITLIST_FORM_INITIAL_VALUES = {
  email: ""
};

export const LOGIN_FORM_VALIDATION_SCHEMA = yup.object().shape({
  email: yup.string().email("Невалиден имейл адрес").required("Задължително поле"),
  password: yup.string().required("Задължително поле"),
});

export const RESET_PASSWORD_FORM_VALIDATION_SCHEMA = yup.object().shape({
  email: yup.string().email("Невалиден имейл адрес").required("Задължително поле"),
});

export const SIGNUP_FORM_VALIDATION_SCHEMA = yup.object().shape({
  email: yup.string().email("Невалиден имейл адрес").required("Задължително поле"),
  firstName: yup.string().required("Задължително поле"),
  lastName: yup.string().required("Задължително поле"),
  password: yup.string().required("Задължително поле"),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password")], "Паролите трябва да съвпадат")
    .required("Задължително поле"),
});

export const WAITLIST_FORM_VALIDATION_SCHEMA = yup.object().shape({
  email: yup.string().email("Невалиден имейл адрес").required("Задължително поле")
});
