import React, { useState } from "react"
import MyListBox from "components/commons/MyListBox";
import DialogModal from "components/commons/DialogModal";
import ClockCountdown from "components/commons/ClockCountdown";
import { PlayCircleIcon, AcademicCapIcon, DocumentTextIcon, ClockIcon } from '@heroicons/react/20/solid'

function studyModeIcon() {
  return (
    <AcademicCapIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
  )
}

function examModeIcon() {
  return (
    <DocumentTextIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
  )
}

const modes = [
  {id: 'learn', name: 'Обучителен режим', description: 'Решете теста с включени жокери към въпросите.', checkIcon: studyModeIcon()},
  {id: 'exam', name: 'Режим "Изпит"', description: 'Решете теста без жокери за ограничено време.', checkIcon: examModeIcon()},
]

export default function ChooseMode({
  setMode,
  setExamModeRunning,
  timeForTakingInSeconds,
  onExamTimeOver
}) {
  const [currentMode, setCurrentMode] = useState(modes[0].id)
  const [examRunning, setExamRunning] = useState(false)

  function onModeSelected(e) {
    const mode = modes.find((m) => m.name === e);
    setCurrentMode(mode.id)
    setMode(mode.id)
  }

  function startExam() {
    setExamRunning(true);
    setExamModeRunning(true);
  }

  function timeForTaking() {
    return `${Math.round(timeForTakingInSeconds / 60)} минути`
  }

  function startExamButton() {
    return (
      <div className="bg-white rounded-md p-4 text-center mt-5 mx-auto w-fit">
        <div>
          <span className="font-medium text-neutral-900">
            Време за решение:
          </span>{' '}
          <span className="text-base font-semibold leading-6 text-neutral-900">
            {timeForTaking()}
          </span>
        </div>

        <button onClick={startExam} type="button" className="inline-flex items-center gap-x-1.5 rounded-md bg-green-50 px-3 py-2 text-sm font-semibold text-green-600 shadow-sm hover:bg-green-100 mt-7">
          <PlayCircleIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
          Стартирай теста
        </button>
      </div>
    )
  }

  function modeDropdown() {
    return (
      <div className="sm:flex sm:items-start sm:justify-between">
        <div>
          <h3 className="text-base font-semibold leading-6 text-gray-900">Изберете режим</h3>
          <div className="mt-2 text-sm text-gray-500">
            <p>
              <u>Обучителният режим</u> включва жокери към въпросите. <u>Режим "Изпит"</u> скрива жокерите и добавя времеви лимит за решението на теста.
            </p>
          </div>
        </div>
        <div className="mt-5 sm:ml-6 sm:mt-0 sm:flex sm:flex-shrink-0 sm:items-center">
          <MyListBox values={modes} onSelection={onModeSelected} />
        </div>
      </div>
    )
  }

  function renderExamModal() {
    return (
      <DialogModal
        title={`Време за решение: ${timeForTaking()}`}
        subtitle={'Режим "Изпит" скрива всички жокери по време на решаване на теста. '}
        cancelLabel={'Обратно към теста'}
        proceedLabel={'Старт на теста'}
        proceedButtonColorClasses={'bg-green-600 hover:bg-green-500 focus-visible:outline-green-600'}
        onProceed={startExam}
        iconOnTop={
          <>
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
              <DocumentTextIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
            </div>
          </>
        }
      />
    )
  }

  function renderClock() {
    return (
      <div className="rounded-md bg-green-50 p-1.5 sm:p-3">
        <div className="ml-1 flex justify-between">
          <div className="my-auto flex">
            <div className="flex-shrink-0">
              <ClockIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
            </div>
            <p className="ml-1 text-sm text-green-700">Оставащо време:</p>
            <span className="px-1.5 text-green-700 font-semibold">
              <ClockCountdown timeInSeconds={timeForTakingInSeconds} onCountdownOver={onExamTimeOver} />
            </span>
          </div>
          <p className="text-sm md:ml-6">
            <button
              onClick={onExamTimeOver}
              type="button"
              className="rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
            >
              Завърши теста
            </button>
          </p>
        </div>
      </div>
    )
  }

  return (
    <div className={`${examRunning ? 'sticky top-[63px] z-20 mb-4 sm:mb-6' : 'px-3 py-3 sm:p-6 bg-neutral-100 sm:rounded-lg my-2 sm:my-7'} shadow mx-auto max-w-3xl`}>
      {!examRunning && modeDropdown()}

      {currentMode === 'exam' && !examRunning && startExamButton()}
      {examRunning && renderClock()}

      {currentMode === 'exam' && renderExamModal()}
    </div>
  )
}
