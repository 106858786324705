import Login from "components/Authentication/Login";
import PasswordReset from "components/Authentication/ResetPassword";
import Signup from "components/Authentication/Signup";
import Waitlist from "components/Authentication/Waitlist";
import Dashboard from "components/Dashboard";
import Home from "components/Dashboard/Home";
import MyTests from "components/Dashboard/MyTests";
import Subjects from "components/Dashboard/Subjects";
import Subscription from "components/Dashboard/Subscription";
import SubjectTests from "components/Dashboard/SubjectTests";
import Test from "components/Dashboard/Test";
import Admin from "components/Dashboard/Admin";
import Settings from "components/Dashboard/Settings";
import Profile from "components/Dashboard/Settings/Profile";
import Email from "components/Dashboard/Settings/Email";
import Password from "components/Dashboard/Settings/Password";

export const DASHBOARD_PATH = "/";
export const QUESTIONS_PATH = "/questions";
export const HOME_PATH = "/home";
export const SUBJECTS_PATH = "/subjects";
export const SUBSCRIPTION_PATH = "/subscription";
export const MY_TESTS_PATH = "/my-tests";
export const SUBJECT_TESTS_PATH = "/subjects/:id/tests";
export const TEST_PATH = "/tests/:id";
export const ADMIN_PATH = "/admin";
export const CHANGE_PASSWORD_PATH = "/settings/password";
export const CHANGE_EMAIL_PATH = "/settings/email";
export const PROFILE_PATH = "/settings/profile";
export const LOGIN_PATH = "/login";
export const SIGNUP_PATH = "/signup";
export const WAITLIST_PATH = "/waitlist";
export const RESET_PASSWORD_PATH = "/my/password/new";

export const AUTH_ROUTES = [
  {
    path: RESET_PASSWORD_PATH,
    component: PasswordReset,
  },
  // {
  //   path: SIGNUP_PATH,
  //   component: Signup,
  // },
  {
    path: LOGIN_PATH,
    component: Login,
  },
  {
    path: WAITLIST_PATH,
    component: Waitlist,
  },
];

export const PRIVATE_ROUTES = [{ path: DASHBOARD_PATH, component: Dashboard }];

export const DASHBOARD_ROUTES = [
  {
    path: HOME_PATH,
    component: Home,
  },
  {
    path: MY_TESTS_PATH,
    component: MyTests,
  },
  {
    path: SUBJECTS_PATH,
    component: Subjects,
  },
  {
    path: SUBSCRIPTION_PATH,
    component: Subscription,
  },
  {
    path: SUBJECT_TESTS_PATH,
    component: SubjectTests,
  },
  {
    path: TEST_PATH,
    component: Test,
  },
  {
    path: ADMIN_PATH,
    component: Admin,
  },
  {
    path: PROFILE_PATH,
    component: Profile,
  },
  {
    path: CHANGE_EMAIL_PATH,
    component: Email,
  },
  {
    path: CHANGE_PASSWORD_PATH,
    component: Password,
  },
];
