import React from "react";

import { useRef } from 'react'
import Link from 'next/link'
import { usePathname } from 'next/navigation'
import clsx from 'clsx'
import { AnimatePresence, motion, useIsPresent } from 'framer-motion/dist/framer-motion'

import { Button } from './Button'
// import { useIsInsideMobileNavigation } from '@/components/MobileNavigation'
// import { useSectionStore } from './SectionProvider'
import { Tag } from './Tag'

export function remToPx(remValue) {
  let rootFontSize =
    typeof window === 'undefined'
      ? 16
      : parseFloat(window.getComputedStyle(document.documentElement).fontSize)

  return remValue * rootFontSize
}

function useIsInsideMobileNavigation() {
  return false
}

function useInitialValue(value, condition = true) {
  let initialValue = useRef(value).current
  return condition ? initialValue : value
}

function TopLevelNavItem({ href, children }) {
  return (
    <li className="md:hidden">
      <Link
        href={href}
        className="block py-1 text-sm text-zinc-600 transition hover:text-primary-950 dark:text-zinc-400 dark:hover:text-white"
      >
        {children}
      </Link>
    </li>
  )
}

function NavLink({
  href,
  children,
  tag,
  active = false,
  isAnchorLink = false,
}) {
  return (
    <Link
      href={href}
      aria-current={active ? 'page' : undefined}
      className={clsx(
        'flex justify-between gap-2 py-1 pr-3 text-sm transition',
        isAnchorLink ? 'pl-7' : 'pl-4',
        active
          ? 'text-zinc-900 dark:text-white'
          : 'text-zinc-600 hover:text-primary-700 dark:text-zinc-400 dark:hover:text-white',
      )}
    >
      <span>{children}</span>
      {tag && (
        <Tag variant="small" color="zinc">
          {tag}
        </Tag>
      )}
    </Link>
  )
}

// function VisibleSectionHighlight({ group, pathname }) {
//   let [sections, visibleSections] = useInitialValue(
//     [
//       useSectionStore((s) => s.sections),
//       useSectionStore((s) => s.visibleSections),
//     ],
//     useIsInsideMobileNavigation()
//   )

//   let isPresent = useIsPresent()
//   let firstVisibleSectionIndex = Math.max(
//     0,
//     [{ id: '_top' }, ...sections].findIndex(
//       (section) => section.id === visibleSections[0],
//     ),
//   )
//   let itemHeight = remToPx(2)
//   let height = isPresent
//     ? Math.max(1, visibleSections.length) * itemHeight
//     : itemHeight
//   let top =
//     group.links.findIndex((link) => link.href === pathname) * itemHeight +
//     firstVisibleSectionIndex * itemHeight

//   return (
//     <motion.div
//       layout
//       initial={{ opacity: 0 }}
//       animate={{ opacity: 1, transition: { delay: 0.2 } }}
//       exit={{ opacity: 0 }}
//       className="absolute inset-x-0 top-0 bg-zinc-800/2.5 will-change-transform dark:bg-white/2.5"
//       style={{ borderRadius: 8, height, top }}
//     />
//   )
// }

// function ActivePageMarker({ group, pathname }) {
//   let itemHeight = remToPx(2)
//   let offset = remToPx(0.25)
//   let activePageIndex = group.links.findIndex((link) => link.href === pathname)
//   let top = offset + activePageIndex * itemHeight

//   return (
//     <motion.div
//       layout
//       className="absolute left-2 h-6 w-px bg-emerald-500"
//       initial={{ opacity: 0 }}
//       animate={{ opacity: 1, transition: { delay: 0.2 } }}
//       exit={{ opacity: 0 }}
//       style={{ top }}
//     />
//   )
// }

function NavigationGroup({ group, className }) {
  // If this is the mobile navigation then we always render the initial
  // state, so that the state does not change during the close animation.
  // The state will still update when we re-open (re-render) the navigation.
  let isInsideMobileNavigation = false
  // let [pathname, sections] = useInitialValue(
  //   [usePathname(), useSectionStore((s) => s.sections)],
  //   isInsideMobileNavigation,
  // )

  let pathname = 'asd'

  let isActiveGroup = false
    // group.links.findIndex((link) => link.href === pathname) !== -1

  return (
    <li className={clsx('relative mt-6', className)}>
      <motion.h2
        layout="position"
        className="text-sm font-semibold text-zinc-900 dark:text-white"
      >
        {group.title}
      </motion.h2>
      <div className="relative mt-3 pl-2">
{/*        <AnimatePresence initial={!isInsideMobileNavigation}>
          {isActiveGroup && (
            <VisibleSectionHighlight group={group} pathname={pathname} />
          )}
        </AnimatePresence>*/}
        <motion.div
          layout
          className="absolute inset-y-0 left-2 w-px bg-zinc-900/10 dark:bg-white/5"
        />
{/*        <AnimatePresence initial={false}>
          {isActiveGroup && (
            <ActivePageMarker group={group} pathname={pathname} />
          )}
        </AnimatePresence>*/}
        <ul role="list" className="border-l border-transparent">
          {group.links.map((link) => (
            <motion.li key={link.href} layout="position" className="relative">
              <NavLink href={link.href} active={link.href === pathname}>
                {link.title}
              </NavLink>
              <AnimatePresence mode="popLayout" initial={false}>
                {link.href === pathname && sections.length > 0 && (
                  <motion.ul
                    role="list"
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: 1,
                      transition: { delay: 0.1 },
                    }}
                    exit={{
                      opacity: 0,
                      transition: { duration: 0.15 },
                    }}
                  >
                    {sections.map((section) => (
                      <li key={section.id}>
                        <NavLink
                          href={`${link.href}#${section.id}`}
                          tag={section.tag}
                          isAnchorLink
                        >
                          {section.title}
                        </NavLink>
                      </li>
                    ))}
                  </motion.ul>
                )}
              </AnimatePresence>
            </motion.li>
          ))}
        </ul>
      </div>
    </li>
  )
}

// Menu format:
// [
//   {
//     title: 'Задължителни',
//     links: [
//       { title: "Математика за 4-ти клас", href: '#neshto' },
//       ...
//     ],
//   },
//   {
//     title: 'Развлекателни',
//     links: [
//       { title: "Български език и литература за 6-ти клас", href: '/contacts' },
//       ...
//     ],
//   }
// ]

const isMandatory = (test) => test.category === 'mandatory'
const isCasual = (test) => test.category === 'casual'

function serializeForMenu(topic) {
  return {title: `${topic.index}. ${topic.title}`, href: `#${topic.id}`}
}

function buildMenu(topics) {
  const mandatory = topics.filter((topic) => topic.tests.some(isMandatory))
  const casual = topics.filter((topic) => topic.tests.some(isCasual))

  return [
    (mandatory.length > 0 && {title: 'Задължителни', links: mandatory.map(serializeForMenu)}),
    (casual.length > 0 && {title: 'Развлекателни', links: casual.map(serializeForMenu)})
  ].filter(Boolean)
}

export default function Navigation({
  topics
}) {
  return (
    <nav className="max-w-full mx-auto md:mx-0">
      <ul role="list" className="sticky top-20">
        {buildMenu(topics).map((group, groupIndex) => (
          <NavigationGroup
            key={group.title}
            group={group}
            className={groupIndex === 0 ? 'md:mt-0' : ''}
          />
        ))}
      </ul>
    </nav>
  )
}
